import { sideOptions } from "../../events/utils/sideOptions";
import { formComponents as fc } from "@/components/form";
import { ui } from "@/components/ui";
import type { SideT } from "@/types/zod/common";
import { useForm } from "react-hook-form";
import { SideZ } from "@/types/zod/common";
import { z } from "zod";
import { DialogClose } from "@/components/ui/dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateInjectionDetailsMutation } from "../api/updateInjectionDetails";

interface Props {
	diaryId: string;
	side: SideT;
	injectionDetailId: string;
}

const schema = z.object({
	side: SideZ,
	injection_detail_id: z.string().uuid(),
});

const EditInjectionDetailsSide = ({
	side,
	injectionDetailId,
	diaryId,
}: Props) => {
	const { mutate: updateInjectionDetails } = useUpdateInjectionDetailsMutation({
		diaryId,
	});

	type FormValues = z.infer<typeof schema>;

	const form = useForm<FormValues>({
		defaultValues: {
			side: side,
			injection_detail_id: injectionDetailId,
		},
		resolver: zodResolver(schema),
	});

	const onSubmit = async (data: FormValues) => {
		updateInjectionDetails(data);
	};
	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.Select name="side" label="Side" options={sideOptions} />
				<DialogClose>
					<ui.Button type="submit" onClick={form.handleSubmit(onSubmit)}>
						Save
					</ui.Button>
				</DialogClose>
			</form>
		</ui.Form>
	);
};

export default EditInjectionDetailsSide;
