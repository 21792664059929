import { ui } from "@/components/ui";
import NoDiarySelected from "@/features/patients/features/interface/components/NoDiarySelected";
import { useRefetchPatientsData } from "@/features/patients/features/overview/table/hooks/useRefetchPatientsData";
import { cn } from "@/lib/utils";
import { isSmall } from "@/services/isSmall";
import type { DiariesCountT, DiaryViewT } from "@/types/database/diaries";
import { Outlet } from "@tanstack/react-router";
import type { PatientsSearchT } from "../../../routes/_app/patients";
import type { ProfileT } from "../../../types/database/profiles";
import PatientsTable from "../features/overview/table/components/PatientsTable";

interface Props {
	diaryId: string;
	diariesCount: DiariesCountT;
	settings: PatientsSearchT;
	diaries: DiaryViewT[];
	profile: ProfileT;
}
const PatientsPage = ({
	diaryId,
	diariesCount,
	settings,
	diaries,
	profile,
}: Props) => {
	useRefetchPatientsData();

	return (
		<div className="px-2 w-full">
			{isSmall() ? (
				diaryId ? (
					<Outlet />
				) : (
					<PatientsTable
						profile={profile}
						diaries={diaries ?? []}
						diariesCount={diariesCount}
						settings={settings}
					/>
				)
			) : (
				<ui.ResizablePanelGroup
					direction="horizontal"
					className="md:min-h-[200px] md:max-h-[92vh] transition-transform duration-1000 ease-in-out"
				>
					<ui.ResizablePanel
						className={cn(
							"md:pr-2",
							diaryId ? "hidden md:flex" : "flex",
							"overflow-scroll",
						)}
						defaultSize={60}
					>
						<PatientsTable
							profile={profile}
							diaries={diaries ?? []}
							diariesCount={diariesCount as DiariesCountT}
							settings={settings}
						/>
					</ui.ResizablePanel>
					<ui.ResizableHandle withHandle className={cn("bg-slate-300")} />
					<ui.ResizablePanel className="p-2" defaultSize={40}>
						{diaryId ? <Outlet /> : <NoDiarySelected />}
					</ui.ResizablePanel>
				</ui.ResizablePanelGroup>
			)}
		</div>
	);
};

export default PatientsPage;
