import { components as c } from "@/components";
import Errors from "@/components/form/Errors";
import GroupedFields from "@/components/form/GroupedFields";
import useOrganisationOptions from "@/components/hooks/useOrganisationOptions";
import { ui } from "@/components/ui";
import { useSecretariesByOrganisationIdQuery } from "@/features/auth/api/selectSecretariesByOrganisation";
import useInjectionOptions from "@/features/injection/hooks/useInjectionOptions";
import InviteForm from "@/features/invite/components/InviteForm";
import { useUpsertNewDiaryTemplate } from "@/features/new-diary/api/upsertNewDiaryTemplate";
import type { NewDiaryTemplate } from "@/types/database/diaries";
import { useAuth } from "@/utils/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

export const newDiaryTemplateSettingsSchema = z.object({
	role: z.enum(["PATIENT"]),
	quickstart_key: z.enum(["injection", "surgery"]),
	invited_by: z.string().uuid(),
	organisation: z.string({
		required_error: "Organisation is required",
	}),
	contact_id: z
		.string()
		.uuid({
			message: "Please select a contact person",
		})
		.nullable(),
	form_id: z.string().uuid().nullable(),
	referring_clinician_id: z
		.string()
		.uuid({
			message: "Please select a referring clinician",
		})
		.nullable(),
	injecting_clinician_id: z
		.string()
		.uuid({
			message: "Please select an injecting clinician",
		})
		.nullable(),
	injection_method_id: z.string().uuid().nullable(),
});

export type NewDiaryTemplateSettingsSchema = z.infer<
	typeof newDiaryTemplateSettingsSchema
>;

const formSchema = z.object({
	template_id: z.string().uuid().optional(),
	user_id: z.string().uuid(),
	new_diary_template_name: z.string().min(1, "Required"),
	new_diary_template_description: z.string().nullable().optional(),
	settings: newDiaryTemplateSettingsSchema,
});

type FormValues = z.infer<typeof formSchema>;

interface Props {
	defaultValues?: NewDiaryTemplate["Row"];
	onSuccess?: () => void;
}

function NewDiaryTemplateForm({ defaultValues }: Props) {
	const { mutate: upsertDiaryTemplate } = useUpsertNewDiaryTemplate();

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: defaultValues,
	});
	const rawOrg = form.watch("settings.organisation");

	const organisation = typeof rawOrg === "string" ? JSON.parse(rawOrg) : rawOrg;

	const organisationId = organisation?.organisation_id;

	const { organisationObjOptions } = useOrganisationOptions();

	const { data: secretaries } =
		useSecretariesByOrganisationIdQuery(organisationId);

	const { methodOptions, clinicianOptions } =
		useInjectionOptions(organisationId);

	function onSubmit(data: FormValues) {
		upsertDiaryTemplate({
			...data,
			settings: {
				...data.settings,
				organisation:
					typeof organisation === "string"
						? JSON.parse(organisation)
						: organisation,
			},
		});
	}

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
				<c.fc.Input
					className="max-w-md text-lg text-slate-900"
					name="new_diary_template_name"
					placeholder="Diary Template Name"
				/>
				<div>
					<c.fc.Textarea
						name="new_diary_template_description"
						placeholder="Diary Template Description"
					/>
				</div>

				<GroupedFields>
					<c.fc.Select
						description="Select the organisation the patient will join"
						name="settings.organisation"
						label="Organisation"
						options={organisationObjOptions}
						required={true}
					/>
					<c.fc.Select
						name="settings.form_id"
						label="Local and/or Secondary Pain"
						description="e.g. Monitor back AND leg pain after a spinal injection."
						clearable={true}
						options={[
							{
								label: "Local Pain Only",
								value: "289abef6-3ce8-4b5b-b621-d622b5efc975",
							},
							{
								label: "Local and Secondary Pain",
								value: "ad5ae755-bfaf-4797-bf10-71d61a5c7465",
							},
						]}
					/>
				</GroupedFields>

				<GroupedFields>
					<c.fc.ComboBox
						label="Referring Clinician"
						name="settings.referring_clinician_id"
						description="The clinician who requests the injection"
						options={clinicianOptions}
						clearable={true}
						addOption={
							<InviteForm
								defaultRole="CLINICIAN"
								defaultOrganisation={rawOrg}
							/>
						}
					/>
					<c.fc.ComboBox
						label="Injecting Clinician"
						name="settings.injecting_clinician_id"
						description="The clinician who performs the injection"
						options={clinicianOptions}
						clearable={true}
						addOption={
							<InviteForm
								defaultRole="CLINICIAN"
								defaultOrganisation={rawOrg}
							/>
						}
					/>
				</GroupedFields>

				<GroupedFields>
					<c.fc.ComboBox
						description="Staff member responsible for patient support and follow-up"
						label="Contact Person"
						placeholder="Contact Person"
						name="settings.contact_id"
						options={
							secretaries?.map((s) => ({
								label: s?.name as string,
								value: s?.user_id as string,
							})) ?? []
						}
						addOption={
							<InviteForm
								defaultRole="SECRETARY"
								defaultOrganisation={rawOrg}
							/>
						}
					/>
					<c.fc.ComboBox
						label="Injection Method"
						name="settings.injection_method_id"
						description="How and where injection is performed"
						options={methodOptions}
						clearable={true}
					/>
				</GroupedFields>
				<Errors />
				<div className="flex justify-end">
					<ui.Button type="submit">Generate QR Code</ui.Button>
				</div>
			</form>
		</ui.Form>
	);
}

export default NewDiaryTemplateForm;
