import { ui } from "@/components/ui";
import {
	diaryByIdQueryOptions,
	useDiaryByIdQuery,
} from "@/features/diary/api/getDiaryById";
import HistoryByTracker from "@/features/diary/history/HistoryByTracker";
import { interfaceComponents as ic } from "@/features/patients/features/interface/components";
import DiaryTitle from "@/features/patients/features/interface/components/DiaryTitle";
import NoDiarySelected from "@/features/patients/features/interface/components/NoDiarySelected";
import { hooks as h } from "@/hooks";
import { createFileRoute } from "@tanstack/react-router";

const PatientsDiaryIdPage = () => {
	const { diaryId } = Route.useParams();

	const { data: diary } = useDiaryByIdQuery(diaryId);

	h.useNav(
		diaryId
			? [
					{ label: "Patients", route: { to: "/patients" } },
					{
						label: diary?.diary_name ?? "Diary",
						route: {
							to: "/patients/$diaryId",
							params: { diaryId: diaryId },
						},
					},
				]
			: [{ label: "Patients", route: { to: "/patients" } }],
	);

	if (!diary) return <NoDiarySelected />;

	return (
		<section className="flex flex-col h-full">
			<ui.ScrollArea>
				<div className="flex flex-col gap-2 mt-3 h-full">
					<DiaryTitle
						patientName={diary.patient_info?.patient_name as string}
						isEditing={false}
						diaryId={diaryId}
					/>
					<ic.StateCard diary={diary} />
					<ic.AiSummary diary={diary} />
					{/* {diary.trackers_view?.length > 0 && <AllCharts diary={diary} />} */}
					<HistoryByTracker diary={diary} />
					<br className="pt-16" />
				</div>

				<ui.ScrollBar className="translate-x-6" />
			</ui.ScrollArea>
		</section>
	);
};

export const Route = createFileRoute("/_app/patients/$diaryId/_diary/")({
	component: PatientsDiaryIdPage,
	loader: async ({ context, params }) => {
		const { diaryId } = params;
		const diary = await context.queryClient.ensureQueryData(
			diaryByIdQueryOptions(diaryId),
		);
		return { diary };
	},
});
