import Section from "@/features/website/components/Section";

import AnimateInView from "../../../components/ui/animate-in-view.tsx";
import HeroContent from "../components/HeroContent.tsx";
import patientsDesktop from "../pain-watch/assets/clinician_app.png";

const DoctorFlow = () => {
	return (
		<Section row={true}>
			<div className="my-auto pr-0 md:pr-8 w-full">
				<HeroContent caption="Doctor Patients" title="Quick for Clinicians" />
				<div className="flex flex-col justify-center items-center gap-y-4 mr-auto max-w-[600px] text-md">
					<p>
						The clinician workflow aims to automate as many simple jobs as
						possible. We provide the tools - you make the decisions:
					</p>
					<div className="space-y-4 px-8 py-4">
						<p className="flex gap-x-2">
							<strong>1 </strong>Invite a patient.
						</p>
						<p className="flex gap-x-2">
							<strong>2 </strong>
							Get weekly round-ups of diaries which may require action.
						</p>
						<p className="flex gap-x-2">
							<strong className="">3 </strong>
							Dismiss Alert, send a pre-written email, or generate a PDF report
							in just two clicks.
						</p>
					</div>
					<p className="pt-4">
						Diaries relevant to each clinician are summarised on their personal
						patients
					</p>
				</div>
			</div>

			<AnimateInView>
				<div className="mx-auto my-auto w-full max-w-[600px]">
					<img src={patientsDesktop} alt="Doctor Patients Desktop" />
				</div>
			</AnimateInView>
		</Section>
	);
};

export default DoctorFlow;
