export const timeunitly = (
	timeunit: "second" | "minute" | "hour" | "day" | "week" | "month" | "year",
) => {
	switch (timeunit) {
		case "second":
			return "every second";
		case "minute":
			return "every minute";
		case "hour":
			return "hourly";
		case "day":
			return "daily";
		case "week":
			return "weekly";
		case "month":
			return "monthly";
		case "year":
			return "yearly";
		default:
			return timeunit;
	}
};
