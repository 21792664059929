import { type SubmitHandler, useForm, z, zodResolver } from "@/utils/form";
import { Link } from "lucide-react";
import React from "react";
import Call from "../../../components/common/Call";
import { Copy } from "../../../components/common/Copy";
import { formComponents as fc } from "../../../components/form";
import { ui } from "../../../components/ui";
import Autocomplete from "../../../components/ui/autocomplete";
import { useProfileQuery } from "../../profile/api/selectProfile";
import { useSelectProfileByEmail } from "../../profile/api/selectProfileByEmail";
import { useImpersonateMutation } from "../api/useImpersonateMutation";
const ImpersonateSchemaZ = z.object({
	user_email: z.string().email(),
	admin_email: z.string().email(),
});

export type ImpersonateSchemaT = z.infer<typeof ImpersonateSchemaZ>;

interface ErrorBoundaryProps {
	children: React.ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

function ErrorBoundary({ children }: ErrorBoundaryProps) {
	const [state, setState] = React.useState<ErrorBoundaryState>({
		hasError: false,
	});

	React.useEffect(() => {
		const handleError = () => setState({ hasError: true });
		window.addEventListener("error", handleError);
		return () => window.removeEventListener("error", handleError);
	}, []);

	if (state.hasError) {
		return <div>Something went wrong.</div>;
	}

	return <>{children}</>;
}

const ImpersonateUser = () => {
	const [impersonateLink, setImpersonateLink] = React.useState<any>(undefined);
	const { data: profile } = useProfileQuery();
	const { mutate: impersonate } = useImpersonateMutation();

	const form = useForm<ImpersonateSchemaT>({
		resolver: zodResolver(ImpersonateSchemaZ),
		defaultValues: {
			admin_email: profile?.email ?? "",
		},
	});

	const { data: emails } = useSelectProfileByEmail(form.watch("user_email"));

	const fetchSuggestions = async () => {
		const options = emails?.map((p) => p.email) ?? [];
		return options;
	};

	const onSubmit: SubmitHandler<ImpersonateSchemaT> = async (formData) => {
		await impersonate(formData, {
			onSuccess: (res) => {
				console.info(res.properties.action_link);
				setImpersonateLink(res.properties.action_link);
			},
		});
	};

	return (
		<ErrorBoundary>
			<div className="p-4">
				{impersonateLink && (
					<Call
						icon={<Link />}
						words={
							<Copy id={impersonateLink} value={impersonateLink}>
								Impersonate User
							</Copy>
						}
					/>
				)}
				<ui.Form {...form}>
					<form className="form" onSubmit={form.handleSubmit(onSubmit)}>
						<fc.Input label="Admin Email" name="admin_email" />
						<Autocomplete
							value={form.watch("user_email")}
							fetchSuggestions={fetchSuggestions}
							options={emails?.map((e) => e.email) ?? []}
							onChange={(value) => form.setValue("user_email", value)}
						/>
						<ui.Button>Next</ui.Button>
					</form>
				</ui.Form>
			</div>
		</ErrorBoundary>
	);
};

export default ImpersonateUser;
