import { components as c } from "@/components";
import { tableComponents as tc } from "@/features/patients/features/overview";
import type { PatientsSearchT } from "@/routes/_app/patients";
import type { DiariesCountT, DiaryViewT } from "@/types/database/diaries";
import { useParams } from "@tanstack/react-router";
import { RefreshCw } from "lucide-react";
import { useEffect, useState } from "react";
import { cn } from "../../../../../../lib/utils";
import type { ProfileT } from "../../../../../../types/database/profiles";
import { usePatientsDiariesQuery } from "../../../../api/useSelectPatientsDiaries";
import useDiariesTable from "../hooks/useDiariesTable";
import DiariesTable from "./DiariesTable";
import TableMenu from "./TableMenu";

interface Props {
	settings: PatientsSearchT;
	diariesCount: DiariesCountT;
	diaries: DiaryViewT[];
	profile: ProfileT;
}
function PatientsTable({ settings, diariesCount, diaries, profile }: Props) {
	const { diaryId } = useParams({ strict: false }) as {
		diaryId: string | undefined;
	};

	const { isFetching, refetch: refetchPatientsData } =
		usePatientsDiariesQuery(settings);

	const getCount = (
		obj: DiariesCountT | undefined,
		key: string | undefined,
	): number => {
		if (!obj || !key) return 0;
		return Object.prototype.hasOwnProperty.call(obj, key)
			? obj[key as keyof DiariesCountT]
			: 0;
	};

	const [count, setCount] = useState<number>(0);

	const { table, paginationRange } = useDiariesTable({
		diaries: diaries ?? [],
	});

	useEffect(() => {
		const newCount = getCount(diariesCount, settings.tab);
		setCount(newCount);
	}, [diariesCount, settings.tab, getCount]);

	return (
		<div className="w-full">
			<div className="flex flex-row flex-wrap-reverse gap-2 justify-between my-2">
				<div className="flex justify-between items-center md:justify-center md:w-fit">
					<tc.StateTabs tab={settings.tab} diariesCount={diariesCount} />
					<c.ui.Button
						size="icon"
						variant="ghost"
						disabled={isFetching}
						className={cn(isFetching && "animate-spin")}
						onClick={() => refetchPatientsData()}
					>
						<RefreshCw size={20} />
					</c.ui.Button>
				</div>

				<div className="flex gap-x-2 justify-between items-center w-full md:justify-center md:w-fit">
					<tc.Search />
					{profile.is_super_user && <TableMenu />}
				</div>
			</div>
			<DiariesTable table={table} diaryId={diaryId} />
			<div className="flex justify-end items-center py-4 space-x-2">
				<div className="flex-1 text-sm text-muted-foreground">
					{count === 0
						? "No diaries on this page"
						: `Showing diaries ${paginationRange.start} to ${paginationRange.end} of ${paginationRange.total}`}
				</div>
				<div className="space-x-2">
					<c.ui.Button
						variant="outline"
						size="sm"
						onClick={table.previousPage}
						disabled={!table.getCanPreviousPage()}
					>
						Previous
					</c.ui.Button>
					<c.ui.Button
						variant="outline"
						size="sm"
						onClick={table.nextPage}
						disabled={!table.getCanNextPage()}
					>
						Next
					</c.ui.Button>
				</div>
			</div>
		</div>
	);
}

export default PatientsTable;
