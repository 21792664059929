import { components as c } from "@/components";
import { buttonVariants } from "@/components/ui/button";
import { injectionApi as ia } from "@/features/injection/api";
import InjectionDetailsForm from "@/features/injection/components/InjectionDetailsForm";
import useExtractInjection from "@/features/injection/hooks/useExtractInjection";
import { cn } from "@/lib/utils";
import { Route as TrackerRoute } from "@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/tracker/$trackerId/tracker-templates";
import { Route as PatientsDiaryRoute } from "@/routes/_app/patients/$diaryId";
import { Link, createFileRoute, useNavigate } from "@tanstack/react-router";

const InjectionDetailsPage = () => {
	const { diaryId, eventId, injectionId, patientId, organisationId } =
		Route.useParams();
	const injection = useExtractInjection(diaryId, injectionId);

	const navigate = useNavigate();
	return (
		<>
			<c.cc.Title words="Injection Details" />
			<c.cc.Stepper
				steps={[
					{ stage: "past", words: "Patient" },
					{ stage: "past", words: "Diary" },
					{ stage: "past", words: "Tracker" },
					{
						stage: "current",
						words: "Event",
						substeps: [
							{
								stage: "past",
								words: "Who & When",
							},
							{
								stage: "current",
								words: "Where & What",
							},
						],
					},
				]}
			/>
			<InjectionDetailsForm
				diaryId={diaryId}
				injectionId={injectionId}
				eventId={eventId}
				injection={injection}
				onSuccess={() =>
					navigate({
						to: PatientsDiaryRoute.to,
						params: {
							diaryId: diaryId,
						},
						search: { tab: "pending" },
					})
				}
			/>
			<Link
				to={TrackerRoute.to}
				params={{
					patientId: patientId,
					organisationId: organisationId,
					diaryId: diaryId,
				}}
				search={{ eventId: eventId }}
				className={cn(
					buttonVariants({ size: "lg", variant: "secondary" }),
					"mt-2",
				)}
			>
				Skip
			</Link>
		</>
	);
};

export const Route = createFileRoute(
	"/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/event/$eventId/injection/$injectionId/details",
)({
	component: InjectionDetailsPage,
	loader: async ({ context, params }) => {
		await context?.queryClient.ensureQueryData(
			ia.injectionQueryOptions(params.injectionId),
		);
		await context?.queryClient.ensureQueryData(
			ia.injectionLocationsQueryOptions(),
		);
		await context?.queryClient.ensureQueryData(
			ia.injectionContentsQueryOptions(),
		);
	},
});
