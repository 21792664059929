import { commonComponents as cc } from "@/components/common";
import { services as s } from "@/services";
import type {
	ExtendedTrackerViewT,
	StatefulTrackerViewT,
} from "@/types/database/trackers";

import { Copy } from "@/components/common/Copy";
import { useAuth } from "@/utils/auth";
import { useDiaryByIdQuery } from "../../diary/api/getDiaryById";
import EditInjectionAsPatient from "../../injection/components/EditInjectionAsPatient";
import TrackerContactPersonForm from "./TrackerContactPersonForm";
import TrackerHospitalNumberForm from "./TrackerHospitalNumberForm";
import TrackerNameForm from "./TrackerNameForm";
import TrackerReferenceDateForm from "./TrackerReferenceDateForm";
import TrackerStartDateForm from "./TrackerStartDateForm";
interface Props {
	tracker: StatefulTrackerViewT | ExtendedTrackerViewT;
}
const EditTracker = ({ tracker }: Props) => {
	const auth = useAuth();
	const { data: diary } = useDiaryByIdQuery(tracker.diary_id as string);
	if (!tracker) {
		return <p>No Tracker</p>;
	}
	return (
		<>
			<cc.EditDialog
				label="Name"
				description="The name of this intervention"
				info={tracker.tracker_name}
				noInfo={!tracker.tracker_name}
			>
				<TrackerNameForm
					diaryId={tracker.diary_id}
					trackerId={tracker.tracker_id}
				/>
			</cc.EditDialog>
			<cc.EditDialog
				label="Start Date"
				description="The date from which you start tracking your pain."
				info={s.time.formatShortDateTime(tracker.start_date)}
				noInfo={!tracker.start_date}
			>
				<TrackerStartDateForm
					diaryId={tracker.diary_id}
					trackerId={tracker.tracker_id}
				/>
			</cc.EditDialog>
			<cc.EditDialog
				label="Reference Date"
				description="The date of the surgery, injection, or medication change, you may not know this yet."
				info={s.time.formatShortDateTime(tracker.reference_date)}
				noInfo={!tracker.reference_date}
			>
				<TrackerReferenceDateForm
					diaryId={tracker.diary_id}
					trackerId={tracker.tracker_id}
				/>
			</cc.EditDialog>
			<cc.EditDialog
				label="Hospital Number"
				description="Your hospital reference number"
				info={tracker.hospital_number}
				noInfo={!tracker.hospital_number}
			>
				<TrackerHospitalNumberForm
					diaryId={tracker.diary_id}
					trackerId={tracker.tracker_id}
				/>
			</cc.EditDialog>
			<cc.EditDialog
				locked={!auth.roles?.isClinician}
				label="Contact Person"
				info={
					<Copy
						id="contact-person"
						value={tracker.contact_email ?? ""}
						className="w-full"
					>
						{tracker.contact_email}
					</Copy>
				}
			>
				<TrackerContactPersonForm
					diaryId={tracker.diary_id}
					trackerId={tracker.tracker_id}
				/>
			</cc.EditDialog>

			{diary.events_view
				.filter((e) =>
					tracker.events?.map((ev) => ev.event_id)?.includes(e.event_id),
				)
				.map((e) =>
					e.injections_view.map((iv) => (
						<EditInjectionAsPatient
							diaryId={tracker.diary_id as string}
							injection={iv}
							key={iv.injection_id}
						/>
					)),
				)}
		</>
	);
};

export default EditTracker;
