import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { createGetDiaryKey } from "./getDiaryById";

const useInvalidateDiaries = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const invalidateDiaries = (diaryId?: string) => {
		queryClient.invalidateQueries({
			queryKey: ["diaries"],
		});
		queryClient.invalidateQueries({
			queryKey: ["diaries_view"],
		});
		navigate({
			search: (prev) => ({
				...prev,
				refetcher: Math.ceil(Math.random() * 10000),
			}),
		});
		diaryId &&
			queryClient.invalidateQueries({
				queryKey: createGetDiaryKey(diaryId),
			});
	};
	return invalidateDiaries;
};

export default useInvalidateDiaries;
