import { useCountPatientsDiariesQuery } from "@/features/patients/api/useCountPatientsDiaries";
import { usePatientsDiariesQuery } from "@/features/patients/api/useSelectPatientsDiaries";
import type { PatientsSearchT } from "@/routes/_app/patients";
import { useSearch } from "@tanstack/react-router";

export function useRefetchPatientsData() {
	const search = useSearch({ strict: false }) as PatientsSearchT;
	const { refetch: refetchDiaries } = usePatientsDiariesQuery(search);
	const { refetch: refetchCount } = useCountPatientsDiariesQuery(search);

	const refetchPatientsData = async () => {
		console.log("refetching patients data");
		await Promise.all([refetchDiaries(), refetchCount()]);
	};

	return refetchPatientsData;
}
