import ErrorPage from "@/components/pages/ErrorPage";
import Nav from "@/features/navigation/components/Navigation";
import { Outlet, createFileRoute } from "@tanstack/react-router";
import LoggedInBanner from "../features/auth/components/LoggedInBanner";

export const Route = createFileRoute("/_auth")({
	component: Auth,
	errorComponent: ({ error, reset }) => (
		<ErrorPage title="Authentication Error" error={error} reset={reset} />
	),
});

function Auth() {
	return (
		<>
			<LoggedInBanner />
			<Nav app={false} />
			<Outlet />
		</>
	);
}
