import Call from "@/components/common/Call";
import useIsMyDiary from "@/features/patients/hooks/useIsMyDiary";
import { Info } from "lucide-react";

interface Props {
	organisationName: string;
	patientName: string;
}
const NotYourDiaryCall = ({ organisationName, patientName }: Props) => {
	const { isMyDiary } = useIsMyDiary();

	return (
		<>
			{!isMyDiary && (
				<Call
					className="mb-4"
					variant="warning"
					icon={<Info />}
					words="View Only"
				>
					This diary belongs to {patientName} under the organisation{" "}
					{organisationName}. You cannot make changes.
				</Call>
			)}
		</>
	);
};

export default NotYourDiaryCall;
