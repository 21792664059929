import { ui } from "../../../components/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import * as z from "zod";
import {
	createUniqueInjectionOptions,
	toFilteredUniqueInjectionOptions,
} from "../utils/injectionOptions";
import useInjectionDetailsOptions from "../hooks/useInjectionDetailsOptions";
import Autocomplete from "../../../components/ui/autocomplete";
import Fuse from "fuse.js";
import { Label } from "../../../components/ui/label";
import { useHandleNewInjectionContents } from "../api/handleNewInjectionContents";
import { useAuth } from "../../../utils/auth";

const formSchema = z.object({
	injection_substance: z.string().min(1, "Required"),
	dose: z.string().min(1, "Required"),
	unit: z.string().min(1, "Required"),
});

type Props = {
	defaultArea?: string;
	defaultSite?: string;
};

export function InjectionContentsForm({ defaultArea, defaultSite }: Props) {
	const auth = useAuth();
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			injection_substance: defaultArea || "",
			dose: defaultSite || "",
		},
	});

	const { injectionContents } = useInjectionDetailsOptions();

	const fetchAreaSuggestions = async (search: string) => {
		const options = createUniqueInjectionOptions(
			injectionContents,
			"injection_substance",
		).map(({ value }) => value);

		const fuse = new Fuse(options, {
			threshold: 0.3,
			distance: 100,
		});

		return search ? fuse.search(search).map((result) => result.item) : options;
	};

	const fetchSiteSuggestions = async (search: string) => {
		const options = toFilteredUniqueInjectionOptions(
			injectionContents,
			"dose",
			"injection_substance",
			form.watch("injection_substance"),
		).map(({ value }) => value);

		const fuse = new Fuse(options, {
			threshold: 0.3,
			distance: 100,
		});

		return search ? fuse.search(search).map((result) => result.item) : options;
	};

	const fetchSubsiteSuggestions = async (search: string) => {
		const options = toFilteredUniqueInjectionOptions(
			injectionContents,
			"unit",
			"dose",
			form.watch("dose"),
		).map(({ value }) => value);

		const fuse = new Fuse(options, {
			threshold: 0.3,
			distance: 100,
		});

		return search ? fuse.search(search).map((result) => result.item) : options;
	};

	const { mutate: handleNewInjectionContents } =
		useHandleNewInjectionContents();

	const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (
		values,
	) => {
		handleNewInjectionContents({ user_id: auth.id, contents: values });
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
				<div>
					<Label>Injection Substance</Label>

					<Autocomplete
						fetchSuggestions={fetchAreaSuggestions}
						options={createUniqueInjectionOptions(
							injectionContents,
							"injection_substance",
						).map(({ value }) => value)}
						value={form.watch("injection_substance")}
						onChange={(val) => form.setValue("injection_substance", val)}
					/>
				</div>
				<div>
					<Label>Injection Dose</Label>

					<Autocomplete
						value={form.watch("dose")}
						onChange={(val) => form.setValue("dose", val)}
						fetchSuggestions={fetchSiteSuggestions}
						options={toFilteredUniqueInjectionOptions(
							injectionContents,
							"dose",
							"injection_substance",
							form.watch("injection_substance"),
						).map(({ value }) => value)}
					/>
				</div>
				<div>
					<Label>Injection Unit</Label>
					<Autocomplete
						value={form.watch("unit")}
						onChange={(val) => form.setValue("unit", val)}
						fetchSuggestions={fetchSubsiteSuggestions}
						options={toFilteredUniqueInjectionOptions(
							injectionContents,
							"unit",
							"dose",
							form.watch("dose"),
						).map(({ value }) => value)}
					/>
				</div>

				<div className="flex justify-end gap-2">
					<ui.DialogClose>
						<ui.Button variant="outline" onClick={() => form.reset()}>
							Cancel
						</ui.Button>
					</ui.DialogClose>

					<ui.DialogClose>
						<ui.Button type="submit">Submit</ui.Button>
					</ui.DialogClose>
				</div>
			</form>
		</ui.Form>
	);
}

export default InjectionContentsForm;
