import { components as c } from "@/components";
import { ui } from "@/components/ui";
import { createFileRoute, useRouter } from "@tanstack/react-router";
import { Plus } from "lucide-react";

import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import NewDiaryTemplateDubQrCode from "@/features/dub/components/NewDiaryTemplateDubQrCode";
import {
	newDiaryTemplateQueryOptions,
	useNewDiaryTemplates,
} from "@/features/new-diary/api/selectNewDiaryTemplates";
import NewDiaryTabs from "@/features/new-diary/features/NewDiaryTabs";

import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import { useDeleteNewDiaryTemplate } from "@/features/new-diary/api/deleteNewDiaryTemplate";
import NewDiaryTemplateForm from "@/features/new-diary/features/NewDiaryTemplateForm";
import { useState } from "react";
import { useAuth } from "../../../utils/auth";

export const Route = createFileRoute("/_app/new-diary/qr-code")({
	component: QRCodeForm,
	loader: async ({ context }) => {
		return await context.queryClient.ensureQueryData(
			newDiaryTemplateQueryOptions(),
		);
	},
});

function QRCodeForm() {
	const auth = useAuth();
	const router = useRouter();
	const { mutate: deleteNewDiaryTemplate } = useDeleteNewDiaryTemplate();
	const { data: newDiaryTemplates } = useNewDiaryTemplates();
	const [editingTemplate, setEditingTemplate] = useState<
		(typeof newDiaryTemplates)[0] | null
	>(null);

	const createUrl = (data) => {
		const href = router.buildLocation({
			to: "/signup",
			search: data,
		}).href;

		return window.location.origin + href;
	};

	return (
		<>
			<c.cc.Title words="New Diary" />
			<NewDiaryTabs value="qr-code" />
			<c.cc.Description>
				QR codes provide a streamlined way to onboard patients to their diaries.
				Simply print and display the codes in waiting rooms or other accessible
				locations. Each clinician can create multiple customized QR codes,
				eliminating the need for repetitive form completion.
			</c.cc.Description>

			<ul className="gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8">
				<li>
					<Dialog
						defaultOpen={newDiaryTemplates?.length === 0}
						open={editingTemplate !== null}
						onOpenChange={(open) => !open && setEditingTemplate(null)}
					>
						<DialogTrigger
							asChild
							onClick={() =>
								setEditingTemplate({
									user_id: auth.id,
									settings: {
										role: "PATIENT",
										quickstart_key: "injection",
										invited_by: auth.id,
										form_id: null,
										contact_id: null,
										injecting_clinician_id: null,
										referring_clinician_id: null,
										injection_method_id: null,
									},
								})
							}
						>
							<Card className="group flex flex-col justify-between hover:border-primary/50 h-[600px] transition-colors cursor-pointer">
								<CardHeader>
									<CardTitle className="text-center">
										Add New Template
									</CardTitle>
									<CardDescription className="line-clamp-4 text-center">
										Generate templates for common injections scenarios such as
										for each clinic or for each colleague you work with.
									</CardDescription>
								</CardHeader>
								<CardContent className="flex flex-1 justify-center items-center">
									<div className="mx-auto p-4 w-[300px] h-[300px] aspect-square">
										<div className="group-hover:bg-slate-50 flex flex-col justify-center items-center border-4 border-slate-800 border-dashed w-full h-full">
											<Plus size={48} />
										</div>
									</div>
								</CardContent>
								<CardFooter className="flex justify-center">
									<ui.Button
										variant="link"
										className="group-hover:bg-primary group-hover:text-primary-foreground transition-colors"
									>
										<Plus className="mr-2 w-4 h-4" />
										Create Template
									</ui.Button>
								</CardFooter>
							</Card>
						</DialogTrigger>
						<DialogContent className="max-w-xl">
							<NewDiaryTemplateForm
								defaultValues={editingTemplate ?? undefined}
								onSuccess={() => setEditingTemplate(null)}
							/>
						</DialogContent>
					</Dialog>
				</li>
				{newDiaryTemplates?.map((template) => (
					<li key={template.updated_at} className="w-full h-full">
						<NewDiaryTemplateDubQrCode
							title={template.new_diary_template_name ?? ""}
							description={template.new_diary_template_description ?? ""}
							url={createUrl(template.settings)}
							onDelete={() => deleteNewDiaryTemplate(template.template_id)}
							onEdit={() =>
								setEditingTemplate({
									...template,
									settings: {
										...template.settings,
										organisation: JSON.stringify(
											template.settings.organisation,
										),
									},
								})
							}
							className="h-[600px]"
						/>
					</li>
				))}
			</ul>
		</>
	);
}
