import type { StatefulDiaryT } from "@/types/database/diaries";

import MarkReadButtons from "../../read-unread/components/MarkReadButtons";
import ActionDropdown from "./ActionDropdown";
import DownloadButton from "./DownloadButton";
import MoreDropdown from "./MoreDropdown";

interface Props {
	diary: StatefulDiaryT;
}
const InterfaceButtons = ({ diary }: Props) => {
	return (
		<div className="flex justify-between items-center mt-1">
			<div className="flex items-center">
				<DownloadButton patientId={diary.patient_id} diaryId={diary.diary_id} />
				<MarkReadButtons diary={diary} />
			</div>
			<div className="flex flex-row gap-2">
				<MoreDropdown diary={diary} />
				<ActionDropdown actionOptions={diary.allowed_actions} />
			</div>
		</div>
	);
};

export default InterfaceButtons;
