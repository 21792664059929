import { supabaseClient } from "@/lib/supabase";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import type { InjectionDetailViewT } from "@/types/database/events";
import { createGetDiaryKey } from "../../diary/api/getDiaryById";

type UpdateInjectionDetails = Partial<InjectionDetailViewT> & {
	injection_detail_id: string;
};

export async function updateInjectionDetails(req: UpdateInjectionDetails) {
	const { data, error } = await supabaseClient
		.from("injection_details")
		.update(req)
		.eq("injection_detail_id", req.injection_detail_id)
		.select()
		.single();

	if (error) throw error;
	return data;
}

export const useUpdateInjectionDetailsMutation = ({
	diaryId,
}: { diaryId: string }) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["injection-details"],
		mutationFn: updateInjectionDetails,
		onError: () => {
			toast.error("Error updating injection details");
		},
		onSuccess: () => {
			toast.success("Injection details updated");
			queryClient.invalidateQueries({ queryKey: ["events"] });
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: createGetDiaryKey(diaryId) });
		},
	});
};

type UpsertInjectionDetails = Partial<InjectionDetailViewT> & {
	injection_detail_id: string;
};
export async function upsertInjectionDiary(req: UpsertInjectionDetails) {
	const { data, error } = await supabaseClient
		.from("injection_details")
		.upsert(req)
		.select()
		.single();
	if (error) throw error;
	return data;
}

export const useUpsertInjectionDetailsMutation = () => {
	return useMutation({
		mutationKey: ["injection-details"],
		mutationFn: async (req: UpsertInjectionDetails) => {
			return await upsertInjectionDiary(req);
		},
		onError: () => {
			toast.error("Error saving injection diary default");
		},
		onSuccess: () => {
			toast.success("Injection diary default saved");
		},
	});
};
