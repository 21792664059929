import Title from "@/components/common/Title";
import { ui } from "@/components/ui";
import {
	type ClinicianStatisticWithProfileT,
	selectCliniciansStatisticsQueryOptions,
} from "@/features/admin/api/selectCliniciansStatistics";
import CopyCell from "@/features/patients/features/overview/table/components/CopyCell";
import { selectCliniciansProfilesQueryOptions } from "@/features/profile/api/selectClinicians";
import { services as s } from "@/services";
import { createFileRoute } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { groupBy, sortBy } from "underscore";
const groupCliniciansStatistics = (
	cliniciansStats: ClinicianStatisticWithProfileT[],
) => {
	// Group by clinician_id
	const statsByClinician = groupBy(cliniciansStats, "clinician_id");

	// For each clinician, get only their most recent stat
	const lastStatsByClinician = Object.values(statsByClinician).map(
		(clinicianStats) =>
			// Sort by created_at in descending order and take the first one
			sortBy(clinicianStats, (stat) => dayjs(stat.created_at)).reverse()[0],
	);
	return lastStatsByClinician;
};

const CliniciansStatisticsPage = () => {
	const { cliniciansStats } = Route.useLoaderData();
	const [showAll, setShowAll] = useState(false);

	const clinicianStatistics = useMemo(() => {
		const lastStatByClinician = groupCliniciansStatistics(cliniciansStats);
		const sortedByRecentlyCreated = lastStatByClinician.sort((a, b) =>
			dayjs(b?.recently_created_diaries).isAfter(
				dayjs(a?.recently_created_diaries),
			)
				? 1
				: -1,
		);
		return sortedByRecentlyCreated;
	}, [cliniciansStats, showAll]);

	const shownClinicianStatistics = useMemo(() => {
		return showAll ? clinicianStatistics : clinicianStatistics.slice(0, 5);
	}, [clinicianStatistics, showAll]);

	return (
		<div className="xl-width">
			<Title words="Clinicians Statistics" />
			<ui.Card className="mx-4 mb-8">
				<ui.CardHeader>
					<ui.CardTitle>Recently Created Diaries Leaderboard</ui.CardTitle>
				</ui.CardHeader>
				<ui.CardContent>
					<ui.Table>
						<ui.TableHeader>
							<ui.TableRow>
								<ui.TableHead>Name</ui.TableHead>
								<ui.TableHead>Email</ui.TableHead>
								<ui.TableHead>User Confirmed</ui.TableHead>
								<ui.TableHead>Organisations</ui.TableHead>
								<ui.TableHead>Recently Created</ui.TableHead>
								<ui.TableHead>Total Created</ui.TableHead>
								<ui.TableHead>Active</ui.TableHead>
								<ui.TableHead>Pending</ui.TableHead>
								<ui.TableHead>Inactive</ui.TableHead>
							</ui.TableRow>
						</ui.TableHeader>
						<ui.TableBody>
							{shownClinicianStatistics?.map((cs) => (
								<ui.TableRow key={cs?.clinician_id}>
									<CopyCell
										id={cs?.profile?.name as string}
										value={cs?.profile?.name || ""}
									/>
									<CopyCell
										id={cs?.profile?.email as string}
										value={cs?.profile?.email || ""}
									/>
									<ui.TableCell>
										{s.time.formatDate(cs?.profile.confirmed_at)}
									</ui.TableCell>
									<ui.TableCell>
										{cs?.profile.organisations
											.map((o) => o.organisation_name)
											.join(", ")}
									</ui.TableCell>
									<ui.TableCell>{cs?.recently_created_diaries}</ui.TableCell>
									<ui.TableCell>{cs?.created_diaries}</ui.TableCell>
									<ui.TableCell>{cs?.active_diaries}</ui.TableCell>
									<ui.TableCell>{cs?.pending_diaries}</ui.TableCell>
									<ui.TableCell>{cs?.inactive_diaries}</ui.TableCell>
								</ui.TableRow>
							))}
						</ui.TableBody>
					</ui.Table>
					{clinicianStatistics?.length > 5 && (
						<ui.Button onClick={() => setShowAll(!showAll)} className="mt-4">
							{showAll ? "Show Top 5" : "Show All"}
						</ui.Button>
					)}
				</ui.CardContent>
			</ui.Card>
		</div>
	);
};

export const Route = createFileRoute("/_app/admin/clinicians/leaderboard")({
	component: CliniciansStatisticsPage,
	loader: async ({ context }) => {
		const cliniciansStats = await context?.queryClient.ensureQueryData(
			selectCliniciansStatisticsQueryOptions(),
		);
		const cliniciansProfiles = await context?.queryClient.ensureQueryData(
			selectCliniciansProfilesQueryOptions(),
		);

		return { cliniciansStats, cliniciansProfiles };
	},
});
