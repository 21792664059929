import { ui } from "@/components/ui";
import {
	diaryByIdQueryOptions,
	useDiaryByIdQuery,
} from "@/features/diary/api/getDiaryById";
import DiaryTitle from "@/features/patients/features/interface/components/DiaryTitle";
import { useProfileByIdQuery } from "@/features/profile/api/selectProfile";
import {
	DateOfBirthDialog,
	EmailDialog,
	NameDialog,
	PhoneDialog,
} from "@/features/profile/components/EditProfileDialogs";
import EditHospitalNumberDialog from "@/features/trackers/components/EditHospitalNumberDialog";
import type { ExtendedProfileT } from "@/types/database/profiles";
import { Link, createFileRoute } from "@tanstack/react-router";
import { ArrowLeft, X } from "lucide-react";

const EditDiaryPatientsPage = () => {
	const { diaryId } = Route.useParams();
	const { data: diary } = useDiaryByIdQuery(diaryId);
	const { data: profile } = useProfileByIdQuery(diary?.patient_id);
	return (
		<section className="flex flex-col h-full">
			{/* <ui.Button asChild variant="outline" size="md" className="mx-0 mt-2">
				<Link
					data-testid="back-to-diary-button"
					to="/patients/$diaryId"
					diaryId={{ diaryId: diaryId }}
					search={(prev) => ({ ...prev })}
				>
					<ArrowLeft />
					Back to Diary
				</Link>
			</ui.Button> */}
			<div className="flex flex-col gap-2 mt-3 px-1 pb-8 h-full overflow-y-scroll">
				<DiaryTitle
					patientName={profile?.name as string}
					isEditing={true}
					diaryId={diaryId}
				/>
				{/* <PatientCard profile={profile} editable={true} /> */}
				<ui.Card className="">
					<ui.CardHeader>
						<ui.CardTitle className="text-xl">Patient Details</ui.CardTitle>
					</ui.CardHeader>
					<ui.CardContent className="">
						<NameDialog editable={true} profile={profile as ExtendedProfileT} />
						<DateOfBirthDialog
							editable={true}
							profile={profile as ExtendedProfileT}
						/>
						<EmailDialog
							editable={true}
							profile={profile as ExtendedProfileT}
							locked={true}
						/>
						<PhoneDialog
							editable={true}
							profile={profile as ExtendedProfileT}
							locked={true}
						/>
						{diary.trackers_view.map((tracker) => (
							<EditHospitalNumberDialog
								key={tracker.tracker_id}
								trackerId={tracker.tracker_id}
								hospitalNumber={tracker.hospital_number}
							/>
						))}
						{/* <NhsNumberDialog profile={profile} /> */}
					</ui.CardContent>
				</ui.Card>
			</div>
		</section>
	);
};
export const Route = createFileRoute("/_app/patients/$diaryId/_diary/edit")({
	component: EditDiaryPatientsPage,
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(
			diaryByIdQueryOptions(params.diaryId),
		);
		return { diary: diary };
	},
});
