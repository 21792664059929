import ErrorPage from "@/components/pages/ErrorPage";
import Navigation from "@/features/navigation/components/Navigation";
import Footer from "@/features/website/components/Footer";
import { useAuth } from "@/utils/auth";
import {
	Outlet,
	ScrollRestoration,
	createFileRoute,
} from "@tanstack/react-router";
import { useEffect, useState } from "react";
import LoggedInBanner from "../features/auth/components/LoggedInBanner";
import { StartDemoDialog } from "../features/demo/StartDemoDialog";

export const Route = createFileRoute("/_website")({
	component: LayoutComponent,
	errorComponent: ({ error, reset }) => (
		<ErrorPage title="Website Error" error={error} reset={reset} />
	),
});

function LayoutComponent() {
	const [showBanner, setShowBanner] = useState(false);
	const auth = useAuth();
	useEffect(() => {
		if (auth.isAuthenticated) {
			setShowBanner(true);
		}
	}, [auth.isAuthenticated]);

	return (
		<main className="pb-2 min-h-screen">
			<ScrollRestoration />
			<LoggedInBanner />
			<StartDemoDialog isOpen={showBanner} onOpenChange={setShowBanner} />
			<Navigation app={false} />
			<Outlet />
			<div className="px-8 py-8">
				<hr className="border-slate-300" />
			</div>
			<Footer auth={auth} />
		</main>
	);
}
