import { supabaseClient } from "@/lib/supabase";
import { queryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

export async function selectPatientLikeEmailByOrganisationId({
	email,
	organisationId,
}: EmailOrganisationId) {
	const { data, error } = await supabaseClient
		.from("profiles")
		.select(
			"email, first_name, last_name, title, roles, users_organisations_roles(*)",
		)
		.eq("users_organisations_roles.organisation_id", organisationId)
		.eq("users_organisations_roles.role", "PATIENT")
		.ilike("email", `%${email}%`);
	if (error) throw error;
	return data;
}

type EmailOrganisationId = {
	email: string;
	organisationId: string;
};

export const selectPatientLikeEmailByOrganisationIdQueryOptions = ({
	email,
	organisationId,
}: EmailOrganisationId) =>
	queryOptions({
		queryKey: ["profiles", email],
		queryFn: () =>
			selectPatientLikeEmailByOrganisationId({ organisationId, email }),
		enabled: !!email,
	});

export function useSelectPatientLikeEmailByOrganisationId({
	email,
	organisationId,
}: EmailOrganisationId) {
	const options = selectPatientLikeEmailByOrganisationIdQueryOptions({
		organisationId,
		email,
	});
	return useQuery(options);
}
