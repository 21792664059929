import { supabaseClient } from "@/lib/supabase";
import type { CreateInjectionDiarySchemaT } from "@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary";
import type { Diary } from "@/types/database/diaries";
import type { CreateInjectionDiaryT } from "@/types/database/events";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { toast } from "sonner";
import useInvalidateDiaries from "../../diary/api/invalidateDiaries";

const createInjectionDiary = async (diaryInfo: CreateInjectionDiaryT) => {
	const { data, error } = await supabaseClient.rpc(
		"create_injection_diary",
		diaryInfo,
	);
	if (error) throw error;
	return data;
};
export const useCreateInjectionDiaryMutation = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const invalidateDiaries = useInvalidateDiaries();
	return useMutation({
		mutationFn: (data: CreateInjectionDiarySchemaT) => {
			if (!data.injecting_clinician_id) {
				delete data.injecting_clinician_id;
			}
			if (!data.contact_id) {
				delete data.contact_id;
			}
			return createInjectionDiary(data as CreateInjectionDiaryT);
		},
		onError: (error) => {
			toast.error(`${error.message}`);
		},
		onSuccess: (data: Diary["Row"]) => {
			toast.success("Diary created successfully");
			navigate({
				to: "/patients/$diaryId/edit",
				params: { diaryId: data.diary_id as string },
				search: { tab: "pending", diaries: "mine", organisations: [] },
			});
			queryClient.setQueryData(["diary", { diaryId: data.diary_id }], data);
			return data;
		},
		onSettled: (data) => {
			invalidateDiaries(data?.diary_id as string);
		},
	});
};
export default useCreateInjectionDiaryMutation;
