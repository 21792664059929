import { useState } from "react";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../../components/ui/table";
import { capitaliseSentence } from "../../../services/capitaliseSentence";
import CopyCell from "../../patients/features/overview/table/components/CopyCell";
import useFavouriteStaff from "../hooks/useFavouriteStaff";
import FavouriteUserStar from "./FavouriteUserStar";

const FavouriteUsers = () => {
	const { faves, nonFaves } = useFavouriteStaff();
	const allUsers = [...(faves || []), ...(nonFaves || [])].filter(Boolean);
	const [showAll, setShowAll] = useState(false);

	const displayUsers = showAll ? allUsers : allUsers.slice(0, 5);

	const roleVariantMap = {
		PATIENT: "default",
		CLINICIAN: "outline",
		SECRETARY: "secondary",
		ADMIN: "destructive",
	};

	return (
		<>
			{/* Desktop Table View */}
			<div className="hidden relative md:block">
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead className="pl-0 text-md">Name</TableHead>
							<TableHead className="text-md">Email</TableHead>
							<TableHead className="text-md">Roles</TableHead>
							<TableHead className="w-[100px] text-md">Favourite</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{displayUsers.map((user) => (
							<TableRow
								key={user.user_id}
								className="transition-all duration-300 text-md"
							>
								<TableCell className="pl-0">
									<CopyCell
										id={`copy-${user.name}`}
										value={user.name ?? ""}
										className="font-medium"
									/>
								</TableCell>
								<TableCell>
									<CopyCell
										id={`copy-${user.email}`}
										value={user.email ?? ""}
									/>
								</TableCell>
								<TableCell>
									<div className="flex flex-wrap gap-1">
										{user.roles?.map((role) => (
											<Badge variant={roleVariantMap[role]} key={role}>
												{capitaliseSentence(role)}
											</Badge>
										))}
									</div>
								</TableCell>
								<TableCell>
									<FavouriteUserStar
										userId={user.user_id as string}
										favourite={
											faves?.some((f) => f?.user_id === user.user_id) ?? false
										}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				{allUsers.length > 5 && (
					<div className="relative">
						<div className="absolute right-0 bottom-0 left-0 h-20 bg-gradient-to-t from-white to-transparent" />
						<div className="flex relative z-10 justify-center mt-4">
							<Button variant="outline" onClick={() => setShowAll(!showAll)}>
								{showAll ? "Show Less" : "See All"}
							</Button>
						</div>
					</div>
				)}
			</div>

			{/* Mobile List View */}
			<div className="flex relative flex-col gap-4 md:hidden">
				{displayUsers.map((user) => (
					<div
						key={user.user_id}
						className="flex justify-between items-start pb-4 border-b"
					>
						<div className="flex flex-col gap-1">
							<p className="font-medium text-md text-slate-900">
								{user.name ?? ""}
							</p>
							<p className="text-sm font-light text-slate-600">
								{user.email ?? ""}
							</p>
							<div className="flex flex-wrap gap-1 mt-1">
								{user.roles?.map((role) => (
									<Badge
										variant={roleVariantMap[role]}
										key={role}
										className="text-sm"
									>
										{capitaliseSentence(role)}
									</Badge>
								))}
							</div>
						</div>
						<FavouriteUserStar
							userId={user.user_id as string}
							favourite={
								faves?.some((f) => f?.user_id === user.user_id) ?? false
							}
						/>
					</div>
				))}
				{allUsers.length > 5 && (
					<Button
						variant="outline"
						className="mb-2"
						onClick={() => setShowAll(!showAll)}
					>
						{showAll ? "Show Less" : "See All"}
					</Button>
				)}
			</div>
		</>
	);
};

export default FavouriteUsers;
