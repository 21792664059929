import { supabaseClient } from "@/lib/supabase";
import { queryOptions, useQuery } from "@tanstack/react-query";

import type { PatientsSearchT } from "@/routes/_app/patients";
import type { PatientsSettings } from "@/types/database/profiles";
import { useAuth } from "@/utils/auth";
export type PatientsSettingsT = PatientsSettings["Row"] & {
	patients_settings: PatientsSearchT;
};
export async function selectPatientsSettingsByUserId(
	id: string,
): Promise<PatientsSettingsT> {
	const { data, error } = await supabaseClient
		.from("patients_settings")
		.select("*")
		.eq("user_id", id)
		.maybeSingle();
	if (error) {
		console.error(error);
	}
	return data as PatientsSettingsT;
}

export const createPatientsSettingsQueryKey = (id: string) => [
	"patients_settings",
	{ user_id: id },
];

export const selectPatientsSettingsByIdQueryOptions = (userId: string) => {
	return queryOptions({
		queryKey: createPatientsSettingsQueryKey(userId),
		queryFn: () => selectPatientsSettingsByUserId(userId),
	});
};

export function usePatientsSettingsQuery() {
	const auth = useAuth();
	return useQuery(selectPatientsSettingsByIdQueryOptions(auth.id));
}
