import { useFormContext } from "react-hook-form";
import "react-phone-number-input/style.css";

import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import PhoneInput from "react-phone-number-input";

interface Props {
	name?: string;
	tabIndex?: number;
	autoFocus?: boolean;
}

const Phone: React.FC<Props> = ({ name = "phone", autoFocus = true }) => {
	const { control } = useFormContext();
	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel>Phone Number</FormLabel>
					{/* <Input type="text" inputMode="numeric" placeholder="eg 07890 012345" {...field} /> */}
					<FormControl>
						<>
							<PhoneInput
								style={{
									input: { border: "blue" },
									borderRadius: "10px",
								}}
								className="[&>input]:h-12 [&>input]:rounded-md [&>input]:border-slate-300 [&>input]:border [&>input]:px-3 [&>input]:focus-within:border-slate-300 [&>input]:focus-within:outline-2 [&>input]:focus-within:outline-indigo-400 [&>input]:focus-within:ring-0"
								placeholder="Enter phone number"
								value={field.value}
								onChange={field.onChange}
								autoComplete="on"
								defaultCountry="GB"
								international={true}
								autoFocus={autoFocus}
							/>
						</>
					</FormControl>
					<FormDescription>
						Please enter your digit phone number with country code.
					</FormDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};

export default Phone;
