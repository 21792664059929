import { ui } from "@/components/ui";
import type { ExtendedDiaryViewT } from "@/types/database/diaries";
import { UuidZ } from "@/types/zod/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { z } from "zod";

import SelectFromMyOrganisationIds from "../../../organisations/components/SelectFromMyOrganisationIds";
import useUpdateDiary from "../../api/updateDiary";

export const DiarySchemaZ = z.object({
	diary_id: UuidZ,
	organisation_id: z.string().uuid(),
});

export type DiarySchemaT = z.infer<typeof DiarySchemaZ>;

interface Props {
	diary: ExtendedDiaryViewT;
}
const DiaryOrganisationForm = ({ diary }: Props) => {
	const form = useForm<DiarySchemaT>({
		resolver: zodResolver(DiarySchemaZ),
		defaultValues: {
			diary_id: diary.diary_id,
			organisation_id: diary?.organisation_id ?? undefined,
		},
	});

	const updateDiary = useUpdateDiary();

	const onSubmit: SubmitHandler<DiarySchemaT> = async (formData) => {
		updateDiary.mutate({
			diary_id: formData.diary_id,
			organisation_id: formData.organisation_id ?? null,
		});
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<SelectFromMyOrganisationIds />

				<div className="flex flex-col gap-y-4 my-4">
					<ui.Button size="lg" type="submit">
						Next
					</ui.Button>
				</div>
			</form>
		</ui.Form>
	);
};

export default DiaryOrganisationForm;
