import { createFileRoute } from "@tanstack/react-router";
import { newDiaryInviteSchema } from "../_app/new-diary/qr-code";
import { Button } from "../../components/ui/button";

const NewDiaryInvitePage = () => {
	const newDiaryInfo = Route.useSearch();
	console.log(newDiaryInfo);
	const organisation = newDiaryInfo.organisation;
	return (
		<div>
			<h1>New Diary Invite</h1>
			<p>Invite a new diary to your organisation</p>
			<p>
				{organisation?.organisation_name}
				{/* Organisation: {JSON.parse(newDiaryInfo.organisation)?.organisation_name} */}
			</p>
			<Button>Continue</Button>
		</div>
	);
};
export const Route = createFileRoute("/_auth/new-diary-invite")({
	component: NewDiaryInvitePage,
});
