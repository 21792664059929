import DiaryRouteForm from "@/features/new-diary/features/DiaryRouteForm";
import { createFileRoute } from "@tanstack/react-router";
import Title from "../../../components/common/Title";
import NewDiaryTabs from "../../../features/new-diary/features/NewDiaryTabs";

const NewDiaryPage = () => {
	return (
		<>
			<Title words="New Diary" />
			<NewDiaryTabs value="manual" />
			<DiaryRouteForm />
		</>
	);
};

export const Route = createFileRoute("/_app/new-diary/")({
	component: NewDiaryPage,
});
