import type { StatefulDiaryT } from "@/types/database/diaries";
import DiaryOverviewCard from "./DiaryOverviewCard";
import { ui } from "@/components/ui";
import { CheckCircle2, Clock, AlertCircle } from "lucide-react";

interface DiaryListProps {
	diaries: StatefulDiaryT[];
}

const DiaryList: React.FC<DiaryListProps> = ({ diaries }) => {
	const toDo = diaries
		.filter((d) => d.end_date === null)
		.filter((d) => d.tracker_due);
	const upcoming = diaries
		.filter((d) => d.end_date === null)
		.filter((d) => !d.tracker_due);
	const completed = diaries.filter((d) => d.end_date !== null);

	const EmptySection = ({ message }: { message: string }) => (
		<ui.Card className="bg-white">
			<ui.CardContent className="flex justify-center items-center p-8">
				<p className="text-center text-muted-foreground text-sm">{message}</p>
			</ui.CardContent>
		</ui.Card>
	);

	return (
		<div className="space-y-12">
			<section>
				<div className="flex items-center gap-2 mb-4">
					<AlertCircle className="text-destructive" />
					<h2 className="font-bold text-lg text-slate-900 dark:text-slate-100">
						Action Required ({toDo.length})
					</h2>
				</div>
				{toDo.length === 0 ? (
					<EmptySection message="All diaries are up to date - no immediate actions required" />
				) : (
					toDo.map((diary) => (
						<DiaryOverviewCard key={diary.diary_id} diary={diary} />
					))
				)}
			</section>

			<section>
				<div className="flex items-center gap-2 mb-4">
					<Clock className="text-blue-500" />
					<h2 className="font-bold text-lg text-slate-900 dark:text-slate-100">
						Up to Date ({upcoming.length})
					</h2>
				</div>
				{upcoming.length === 0 ? (
					<EmptySection message="No active diaries at the moment" />
				) : (
					upcoming.map((diary) => (
						<DiaryOverviewCard key={diary.diary_id} diary={diary} />
					))
				)}
			</section>

			<section>
				<div className="flex items-center gap-2 mb-4">
					<CheckCircle2 className="text-green-500" />
					<h2 className="font-bold text-lg text-slate-900 dark:text-slate-100">
						Completed ({completed.length})
					</h2>
				</div>
				{completed.length === 0 ? (
					<EmptySection message="No completed diaries yet" />
				) : (
					completed.map((diary) => (
						<DiaryOverviewCard key={diary.diary_id} diary={diary} />
					))
				)}
			</section>
		</div>
	);
};

export default DiaryList;
