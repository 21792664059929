import Banner from "@/components/common/Banner";
import React, { useEffect } from "react";
import { useDemo } from "../../hooks/useDemo";

export function DemoBanner() {
	const isDemoEnvironment = useDemo();

	const [isVisible, toggleVisibility] = React.useState(isDemoEnvironment);

	return (
		<Banner show={isVisible} setShow={toggleVisibility}>
			This is a demo environment. Please do not enter personal information.
		</Banner>
	);
}
