import PatientsButtons from "./PatientsButtons";
import StateTabs from "./StateTabs";
import TableTabs from "./TableTabs";
import Search from "./table/components/Search";

export const tableComponents = {
	PatientsButtons,
	TableTabs,
	StateTabs,
	Search,
};
