import { timeunitly } from "../services/timeunitly";

const TimingExplainer = ({ timing }) => {
	if (timing.repeats === 0)
		return (
			<span className="capitalize">
				{timing.time_unit} {timing.interval}
			</span>
		);
	return (
		<span>
			<span className="capitalize">{timeunitly(timing.time_unit)}</span> for{" "}
			<span className="capitalize">{timing.repeats + 1}</span>{" "}
			<span className="capitalize">{timing.time_unit}s</span> from{" "}
			<span className="capitalize">{timing.time_unit}</span>{" "}
			<span className="capitalize">{timing.interval}</span>
		</span>
	);
};

export default TimingExplainer;
