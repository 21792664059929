import Add from "./Add";
import AddPatientBtn from "./AddPatientBtn";
import Adjust from "./Adjust";
import Amputation from "./Amputation";
import AppleAddToHomescreen from "./AppleAddToHomescreen";
import AppleShare from "./AppleShare";
import Attachment from "./Attachment";
import Back from "./Back";
import BackArrow from "./BackArrow";
import BlueRoundTick from "./BlueRoundTick";
import Camera from "./Camera";
import CameraTick from "./CameraTick";
import CelebrateSittingDrawing from "./CelebrateSittingDrawing";
import Change from "./Change";
import ChevronRight from "./ChevronRight";
import Clipboard from "./Clipboard";
import Color from "./Color";
import Copy from "./Copy";
import Cross from "./Cross";
import CrossThin from "./CrossThin";
import Database from "./Database";
import Deleted from "./Deleted";
import Diaries from "./Diaries";
import DiariesNotify from "./DiariesNotify";
import Diary from "./Diary";
import DiaryIcon from "./DiaryIcon";
import Doctor from "./Doctor";
import Download from "./Download";
import Dryness from "./Dryness";
import Edit from "./Edit";
import EditError from "./EditError";
import Equals from "./Equals";
import ExpandHistory from "./ExpandHistory";
import Filter from "./Filter";
import Flag from "./Flag";
import FlipCamera from "./FlipCamera";
import Foot from "./Foot";
import Footprint from "./Footprint";
import ForwardArrow from "./ForwardArrow";
import Goggles from "./Goggles";
import GreenTick from "./GreenTick";
import HelpHands from "./HelpHands";
import HideShow from "./HideShow";
import Home from "./Home";
import Info from "./Info";
import Install from "./Install";
import Invite from "./Invite";
import InvitePatient from "./InvitePatient";
import InviteUser from "./InviteUser";
import JazzySpinner from "./JazzySpinner";
import Label from "./Label";
import Learn from "./Learn";
import Logo from "./Logo";
import Logout from "./Logout";
import Maximize from "./Maximise";
import Message from "./Message";
import Minimize from "./Minimize";
import Multiply from "./Multiply";
import NoFoot from "./NoFoot";
import NoImages from "./NoImages";
import NoKnives from "./NoKnives";
import NoWifi from "./NoWifi";
import Notification from "./Notification";
import OpenDeleted from "./OpenDeleted";
import Padlock from "./Padlock";
import Patient from "./Patient";
import Dashboard from "./Dashboard";
import DashboardBox from "./DashboardBox";
import Profile from "./Profile";
import Quality from "./Quality";
import Question from "./Question";
import Refresh from "./Refresh";
import Results from "./Results";
import Safe from "./Safe";
import Search from "./Search";
import Send from "./Send";
import Settings from "./Settings";
import Shape from "./Shape";
import Shield from "./Shield";
import Shuffle from "./Shuffle";
import SoleOfFootDrawing from "./SoleOfFootDrawing";
import SortArrow from "./SortArrow";
import SortArrows from "./SortArrows";
import Spinner from "./Spinner";
import Table from "./Table";
import Tick from "./Tick";
import TopOfFootDrawing from "./TopOfFootDrawing";
import Triangle from "./Triangle";
import Update from "./Update";
import UpdateTick from "./UpdateTick";
import Upload from "./Upload";
import Warning from "./Warning";
import WarningRed from "./WarningRed";
import WhiteTick from "./WhiteTick";

export const icons = {
	AppleShare,
	NoFoot,
	Flag,
	Foot,
	Shape,
	Shield,
	Safe,
	Table,
	AppleAddToHomescreen,
	HideShow,
	NoWifi,
	ForwardArrow,
	Learn,
	NoImages,
	Adjust,
	Filter,
	CameraTick,
	Warning,
	InviteUser,
	NoKnives,
	Upload,
	Minimize,
	Maximize,
	Color,
	JazzySpinner,
	BackArrow,
	Label,
	Results,
	HelpHands,
	Install,
	Logo,
	Notification,
	Footprint,
	Copy,
	Invite,
	Profile,
	SortArrow,
	Dashboard,
	SoleOfFootDrawing,
	Dryness,
	TopOfFootDrawing,
	Database,
	Tick,
	Send,
	CelebrateSittingDrawing,
	Shuffle,
	Quality,
	Amputation,
	ChevronRight,
	CrossThin,
	Spinner,
	Logout,
	Settings,
	Equals,
	Message,
	WhiteTick,
	EditError,
	Goggles,
	Download,
	Camera,
	Clipboard,
	Add,
	DashboardBox,
	Home,
	Refresh,
	GreenTick,
	Multiply,
	Cross,
	Back,
	UpdateTick,
	WarningRed,
	Attachment,
	Diary,
	ExpandHistory,
	Info,
	Patient,
	SortArrows,
	Doctor,
	DiaryIcon,
	Diaries,
	Triangle,
	DiariesNotify,
	Question,
	Search,
	Padlock,
	InvitePatient,
	AddPatientBtn,
	Change,
	Edit,
	BlueRoundTick,
	Deleted,
	Update,
	OpenDeleted,
};
