import { supabaseClient } from "@/lib/supabase";
import { queryOptions, useQuery } from "@tanstack/react-query";

export async function getSecretariesByOrganisationId(organisationId: string) {
	const { data, error } = await supabaseClient
		.from("users_organisations_roles")
		.select("*, profiles(*)")
		.eq("role", "SECRETARY")
		.eq("organisation_id", organisationId);
	if (error) throw error;
	const profiles = data.map((d) => d.profiles);
	return profiles;
}

export const secretariesQueryOptions = (organisationId: string) => {
	return queryOptions({
		queryKey: ["secretaries", organisationId],
		queryFn: () => getSecretariesByOrganisationId(organisationId),
		enabled: !!organisationId,
	});
};

export const useSecretariesByOrganisationIdQuery = (organisationId: string) => {
	const options = secretariesQueryOptions(organisationId);
	return useQuery(options);
};
