import { ActionIcon } from "@/assets/actions/ActionIcon";
import { ui } from "@/components/ui";
import { cn } from "@/lib/utils";
import { Route as PatientsDiaryRoute } from "@/routes/_app/patients/$diaryId";
import { services as s } from "@/services";
import type { ActionKeyT } from "@/types/database/actions";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";

interface ActionOptionT {
	key: string;
	name: string;
	email_end: string;
	internal_states: string[];
}

interface Props {
	actionOptions: ActionOptionT[];
}

const style = "flex flex-row gap-x-2 [&_svg]:w-[28px] [&_svg]:h-[28px]";

const SelectAction = (props: Props) => {
	const navigate = useNavigate({ from: PatientsDiaryRoute.fullPath });
	const [action, setAction] = useState<ActionKeyT | undefined>(undefined);
	return (
		<div className="flex justify-center items-center gap-x-2">
			<ui.Select value={action} onValueChange={(val) => setAction(val)}>
				<ui.SelectTrigger>
					<ui.SelectValue className={style} placeholder="Select an Action" />
				</ui.SelectTrigger>
				<ui.SelectContent>
					{props.actionOptions.map((action) => (
						<ui.SelectItem value={action.action_key}>
							<div className={cn(style, "stroke-sm")}>
								{ActionIcon[action.action_key]}
								<p>{action.action_name}</p>
							</div>
						</ui.SelectItem>
					))}
				</ui.SelectContent>
			</ui.Select>
			<ui.Button
				onClick={() => {
					navigate({
						to: "/patients/$diaryId/action/$actionId",
						params: (prev) => ({ ...prev, actionId: s.uuidv4() }),
						search: (prev) => ({ ...prev, action: action }),
					});
				}}
			>
				Next
			</ui.Button>
		</div>
	);
};

export default SelectAction;
