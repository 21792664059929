import { formComponents as fc } from "@/components/form";
import { ui } from "@/components/ui";
import { useForm } from "react-hook-form";
import z from "zod";
import { DialogClose } from "../../../components/ui/dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { useOrthopaedicAreasQuery } from "../api/selectOrthopaedicArea";
import { useUpdateInjectionDetailsMutation } from "../api/updateInjectionDetails";

interface Props {
	injectionDetailId: string;
	orthopaedicArea: string;
	diaryId: string;
}

const EditInjectionDetailsOrthopaedicArea = ({
	injectionDetailId,
	orthopaedicArea,
	diaryId,
}: Props) => {
	const { mutate: updateInjectionDetails } = useUpdateInjectionDetailsMutation({
		diaryId,
	});
	const { data: orthopaedicAreas } = useOrthopaedicAreasQuery();
	const schema = z.object({
		injection_detail_id: z.string().uuid(),
		orthopaedic_area: z.string(),
	});

	type FormValues = z.infer<typeof schema>;

	const form = useForm<FormValues>({
		defaultValues: {
			orthopaedic_area: orthopaedicArea,
			injection_detail_id: injectionDetailId,
		},
		resolver: zodResolver(schema),
	});

	const onSubmit = async (data: FormValues) => {
		updateInjectionDetails(data);
	};
	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.Select
					name="orthopaedic_area"
					label="Anatomic Area"
					options={orthopaedicAreas.map((o) => ({
						value: o.orthopaedic_area,
						label: o.orthopaedic_area,
					}))}
				/>
				<DialogClose>
					<ui.Button type="submit" onClick={form.handleSubmit(onSubmit)}>
						Save
					</ui.Button>
				</DialogClose>
			</form>
		</ui.Form>
	);
};

export default EditInjectionDetailsOrthopaedicArea;
