import { NavProvider, useNav } from "@/utils/nav";
import * as Sentry from "@sentry/react";
import { supabaseIntegration } from "@supabase/sentry-js-integration";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
	type RoutePaths,
	Router,
	RouterProvider,
} from "@tanstack/react-router";
import ReactDOM from "react-dom/client";
import { colors } from "./assets/colors";
import JazzySpinner from "./assets/icons/JazzySpinner";
import { ThemeProvider } from "./hooks/useTheme";
import { supabaseClient } from "./lib/supabase";
import { routeTree } from "./routeTree.gen";
import { AuthProvider, useAuth } from "./utils/auth";
const queryClient = new QueryClient();

// Set up a Router instance
export const router = new Router({
	routeTree,
	defaultPendingComponent: () => (
		<div className="p-2 text-2xl">
			<JazzySpinner fill={colors["indigo-200"]} />
		</div>
	),
	context: {
		queryClient,
		auth: undefined!,
		nav: undefined!,
	},
	defaultPreload: "intent",
	defaultPreloadStaleTime: 0,
});

if (import.meta.env.PROD) {
	Sentry.init({
		dsn: "https://44c0baa9166f288ec50990bf900003ea@o4507629740687361.ingest.de.sentry.io/4507629742850128",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
			Sentry.tanstackRouterBrowserTracingIntegration(router),
			supabaseIntegration(supabaseClient, Sentry, {
				tracing: true,
				breadcrumbs: true,
				errors: true,
			}),
		],
		tracesSampleRate: 1.0,
		tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

export type ValidRoutes = RoutePaths<typeof routeTree>;

// Register things for typesafety
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

const rootElement = document.getElementById("app")!;

function InnerApp() {
	const auth = useAuth();
	const nav = useNav();

	return <RouterProvider router={router} context={{ auth, nav }} />;
}

if (!rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);
	root.render(
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<NavProvider>
					<ThemeProvider>
						<InnerApp />
					</ThemeProvider>
				</NavProvider>
			</AuthProvider>
		</QueryClientProvider>,
	);
}
