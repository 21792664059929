import { components as c } from "@/components";
import { buttonVariants } from "@/components/ui/button";
import {
	useSetRoles,
	usersOrganisationsRolesQueryOptions,
} from "@/features/auth/api/selectUsersOrganisationsRoles";
import { selectProfileByIdQueryOptions } from "@/features/profile/api/selectProfile";
import { cn } from "@/lib/utils";
import { useAuth } from "@/utils/auth";
import { Link, createFileRoute } from "@tanstack/react-router";
import { Check, X } from "lucide-react";
import { useEffect, useState } from "react";

const OnboardingComplete = () => {
	const auth = useAuth();
	const { retry } = useSetRoles();
	const [isLoading, setIsLoading] = useState(true); // Step 2
	// FIXME - this is a dirty fix to ensure that roles are populated before this page loads.

	useEffect(() => {
		const timer = setTimeout(() => {
			retry();
			setIsLoading(false); // Step 3
		}, 2000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<section className="flex flex-col gap-y-4 md-width">
			<c.cc.Title
				words={
					isLoading
						? "Processing New User"
						: auth.roles?.hasRole
							? "Onboarding Complete"
							: "Authentication Error"
				}
			/>
			<p
				className={cn(
					"mx-auto flex h-32 w-32 items-center bg-amber-200 bg-opacity-60 transition-all duration-400 justify-center mb-4 rounded-lg",
					auth?.roles?.hasRole ? "bg-green-200" : "bg-red-200",
					isLoading && "bg-amber-200",
				)}
			>
				{isLoading ? (
					<c.cc.Spinner className="w-14 h-14" />
				) : auth.roles?.hasRole ? (
					<Check size={60} />
				) : (
					<X size={60} />
				)}
			</p>
			{auth.roles?.hasRole ? (
				<Link
					className={cn(buttonVariants({}))}
					to={
						auth.roles?.isPatient
							? "/diaries"
							: auth.roles?.isClinician
								? "/patients"
								: "/profile"
					}
				>
					Next
				</Link>
			) : (
				<c.ui.Button type="button" onClick={() => retry()}>
					Retry
				</c.ui.Button>
			)}
		</section>
	);
};

export const Route = createFileRoute("/onboarding/complete")({
	component: OnboardingComplete,
	loader: async ({ context }) => {
		const roles = await context?.queryClient?.ensureQueryData(
			usersOrganisationsRolesQueryOptions(context.auth.id),
		);
		roles ?? context.auth.roles?.setRoles(roles);

		const profile = await context?.queryClient?.ensureQueryData(
			selectProfileByIdQueryOptions(context.auth.id),
		);
		profile ?? context.auth.setIsSuperUser(profile?.is_super_user ?? false);
	},
});
