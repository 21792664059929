import { supabaseClient } from "@/lib/supabase";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import type { PatientsSearchT } from "@/routes/_app/patients";
import type { PatientsSettings } from "@/types/database/profiles";
import { useNavigate } from "@tanstack/react-router";
import { useRefetchPatientsData } from "../features/overview/table/hooks/useRefetchPatientsData";

export async function upsertPatientsSettings(
	patientsSettings: PatientsSettings["Insert"],
) {
	const { data, error } = await supabaseClient
		.from("patients_settings")
		.upsert(patientsSettings)
		.select()
		.maybeSingle();

	if (error) console.error(error);
	return data;
}

export const useUpsertPatientsSettings = () => {
	const refetchPatientsData = useRefetchPatientsData();
	const navigate = useNavigate();
	return useMutation({
		mutationKey: ["entry"],
		mutationFn: async (patientsSettings: PatientsSettings["Insert"]) => {
			return await upsertPatientsSettings(patientsSettings);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: (data) => {
			toast.info("Patients Settings Saved");
			return data;
		},
		onSettled: (data) => {
			navigate({ search: (prev: PatientsSearchT) => ({ ...prev, ...data }) });
			refetchPatientsData();
			window.location.reload();
		},
	});
};
