import { ClipboardCheck, ClipboardX } from "lucide-react";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../../../../../components/ui/select";
import { cn } from "../../../../../../lib/utils";
import type { DiaryViewT } from "../../../../../../types/database/diaries";
import useUpdateDiary from "../../../../../diary/api/updateDiary";

const SelectMissingState = ({ diary }: { diary: DiaryViewT }) => {
	const { mutate: updateDiary } = useUpdateDiary();
	const isIncomplete = diary.has_incomplete_events;

	return (
		<Select
			value={isIncomplete ? "incomplete" : "complete"}
			onValueChange={(value) => {
				updateDiary({
					diary_id: diary.diary_id,
					has_incomplete_events: value === "incomplete",
				});
			}}
		>
			<SelectTrigger
				className={cn(
					"px-0 py-0 pr-2 min-w-fit rounded-md h-fit  text-slate-800",
					isIncomplete ? "bg-amber-100" : "bg-green-100",
				)}
			>
				<div className="flex items-center gap-1">
					<SelectValue placeholder="Select a state" />
				</div>
			</SelectTrigger>
			<SelectContent>
				<SelectItem value="incomplete">
					<div className="flex items-center gap-1 bg-amber-100 px-1 py-1 rounded-md w-fit text-slate-800">
						<ClipboardX size={24} aria-label="Has incomplete events" />
						<span className="text-sm">Incomplete</span>
					</div>
				</SelectItem>
				<SelectItem value="complete">
					<div className="flex items-center gap-1 bg-green-100 px-1 py-1 rounded-md w-fit text-slate-800">
						<ClipboardCheck size={24} aria-label="All events complete" />
						<span className="text-sm">Complete</span>
					</div>
				</SelectItem>
			</SelectContent>
		</Select>
	);
};

export default SelectMissingState;
