import { supabaseClient } from "@/lib/supabase";
import type { Action } from "@/types/database/actions";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import useInvalidateDiaries from "../../../../diary/api/invalidateDiaries";

export const insertAction = async (data: Action["Insert"]) => {
	return supabaseClient
		.from("actions")
		.insert(data)
		.select("*, action_definitions(*)")
		.throwOnError()
		.single()
		.then((result) => result.data);
};
export const useInsertActionMutation = () => {
	const invalidateDiaries = useInvalidateDiaries();
	return useMutation({
		mutationFn: async (data: Action["Insert"]) => {
			return await insertAction(data);
		},
		onError: (error) => {
			toast.error(`${error.message}`);
		},
		onSuccess: (data) => {
			toast.success(`${data?.action_definitions?.action_name} Complete`);
		},
		onSettled: (data) => {
			invalidateDiaries(data?.diary_id as string);
		},
	});
};
export default useInsertActionMutation;
