import AllCharts from "@/features/charts/components/AllCharts";
import { diaryByIdQueryOptions } from "@/features/diary/api/getDiaryById";
import History from "@/features/diary/history/History";
import { collateDiaryHistory } from "@/features/diary/services/collateDiaryHistory";
import DiaryCard from "@/features/patients/features/interface/components/DiaryCard";
import PatientCard from "@/features/patients/features/patient/PatientCard";
import { selectProfileByIdQueryOptions } from "@/features/profile/api/selectProfile";
import { createFileRoute } from "@tanstack/react-router";

const DiaryPreviewPage = () => {
	const { diary, profile } = Route.useLoaderData();

	return (
		<>
			<PatientCard editable={false} profile={profile} />
			<DiaryCard editable={false} diary={diary} />
			<AllCharts diary={diary} />
			<History
				size="sm"
				editable={false}
				addable={false}
				diaryId={diary.diary_id}
				diaryHistory={collateDiaryHistory(diary)}
			/>
		</>
	);
};

export const Route = createFileRoute(
	"/preview/patient/$patientId/diary/$diaryId/",
)({
	component: DiaryPreviewPage,
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(
			diaryByIdQueryOptions(params.diaryId),
		);
		const profile = await context?.queryClient.ensureQueryData(
			selectProfileByIdQueryOptions(params.patientId),
		);
		return { diary, profile };
	},
});
