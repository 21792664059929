import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Route as PrintDiaryRoute } from "@/routes/preview/patient/$patientId/diary/$diaryId";
import type { StatefulDiaryT } from "@/types/database/diaries";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Link } from "@tanstack/react-router";

interface Props {
	diary: StatefulDiaryT;
}
const TableRowMenu = ({ diary }: Props) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="p-0 w-8 h-8">
					<span className="sr-only">Open menu</span>
					<DotsHorizontalIcon className="w-4 h-4" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				<DropdownMenuLabel>Actions</DropdownMenuLabel>
				<DropdownMenuItem asChild>
					<Link
						to={PrintDiaryRoute.to}
						params={{ diaryId: diary.diary_id, patientId: diary.patient_id }}
						target="_blank"
					>
						<span>Print Diary</span>
					</Link>
				</DropdownMenuItem>
				<DropdownMenuSeparator />
				<DropdownMenuItem asChild>
					<Link
						to="/patients/$diaryId"
						params={{ diaryId: diary.diary_id }}
						search={{}}
					>
						View diary details
					</Link>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default TableRowMenu;
