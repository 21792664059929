import { commonComponents as cc } from "@/components/common";
import { ui } from "@/components/ui";
import { ClipboardCheck } from "lucide-react";

import type { FormViewT, PageViewT } from "@/types/database/forms";
import type {
	DueEntryT,
	StatefulTrackerViewT,
} from "@/types/database/trackers";
import type { RouteT, ToT } from "@/types/zod/routes";
import EntryTimeLabel from "../../diary/new/components/EntryTimeLabel";
import Input from "../../inputs/components/InputSwitch";
import { stepperizeDiaryPages } from "../../inputs/services/stepperizeDiaryPages";
import useFormPage from "../hooks/useFormPage";

interface Props {
	route: ToT;
	pageIndex: number;
	form: FormViewT;
	nextEntry?: StatefulTrackerViewT["next_entry"];
	page: PageViewT;
	diaryId?: string;
	tracker?: StatefulTrackerViewT;
}

export type AnyInputT = string | string[] | number | number;

const FormPage = (props: Props) => {
	const { form, onSubmit, prevPage } = useFormPage({
		inputs: props.page?.inputs,
		route: props.route?.to as RouteT,
		pageCount: props.form?.pages?.length,
		nextEntry: props.nextEntry as DueEntryT,
	});

	if (Number(props.pageIndex) > props.form?.pages?.length) {
		return (
			<div className="flex flex-col gap-4 bg-green-200 mx-auto px-8 py-4 p-2 rounded-lg w-fit text-green-800">
				<div className="flex items-center gap-4 mx-auto">
					<ClipboardCheck size={48} strokeWidth={1.5} />
					<p className="font-medium text-lg">Form Submitted</p>
				</div>

				<ui.Button variant="outline">Next</ui.Button>
			</div>
		);
	}

	return (
		<>
			<cc.Title words={props.form?.form_name ?? "Form"}>
				{props.tracker?.next_entry?.label && (
					<EntryTimeLabel
						className="mt-6"
						label={props.tracker?.next_entry?.label as string}
					/>
				)}
			</cc.Title>

			<cc.Stepper
				steps={stepperizeDiaryPages(
					props.form?.pages,
					props.pageIndex,
					props.route.to,
				)}
			/>
			{props.form?.pages?.length > 1 && <ui.Separator />}
			<ui.Form {...form}>
				<form
					onSubmit={form?.handleSubmit(onSubmit)}
					className="space-y-4 mt-4 text-lg"
				>
					{props.page?.inputs
						?.sort((a, b) => {
							return Number(a?.input_index) - Number(b?.input_index);
						})
						?.map((i) => (
							<div className="mb-4" key={i?.input_id}>
								<Input form={form} input={i} />
							</div>
						))}
					<cc.FooterButtons
						words1="Next"
						words2={props.form?.pages?.length > 1 ? "Back" : undefined}
						func2={props.form?.pages?.length > 1 ? prevPage : undefined}
						disabled2={props.pageIndex === 1}
					/>
				</form>
			</ui.Form>
		</>
	);
};

export default FormPage;
