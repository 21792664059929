import { icons } from "@/assets";

const ProductTitle = () => {
	return (
		<section className="landing section-content">
			<div className="img-row title">
				<icons.Patients />
				<h2>Our Product</h2>
			</div>
		</section>
	);
};

export default ProductTitle;
