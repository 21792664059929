import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
} from "@/components/ui/form";
import { Switch as SwitchElement } from "@/components/ui/switch";
import * as changeCase from "change-case";
import { useFormContext } from "react-hook-form";

interface Props {
	name: string;
	label: string;
	description?: string;
	autoFocus?: boolean;
}

const Switch = ({ name, label, description, autoFocus = false }: Props) => {
	const form = useFormContext();
	return (
		<FormField
			control={form.control}
			name={name}
			render={({ field }) => (
				<FormItem className="flex flex-row justify-between items-center">
					<div className="space-y-0.5">
						<FormLabel className="text-lg">{label}</FormLabel>
						{description && <FormDescription>{description}</FormDescription>}
					</div>
					<FormControl>
						<SwitchElement
							autoFocus={autoFocus}
							data-testid={`${changeCase.kebabCase(name)}-switch`}
							checked={field.value}
							onCheckedChange={field.onChange}
						/>
					</FormControl>
				</FormItem>
			)}
		/>
	);
};

export default Switch;
