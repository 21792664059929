import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";

interface Props {
	children: React.ReactNode;
	className: string;
	diaryId: string;
}

const Cell = ({ children, className, diaryId }: Props) => {
	return (
		<Link
			to="/patients/$diaryId"
			params={{ diaryId: diaryId }}
			search={(prev) => ({ ...prev })}
			className={cn(
				"flex justify-start items-center p-4 h-full text-nowrap",
				className,
			)}
		>
			{children}
		</Link>
	);
};

export default Cell;
