import type { EntryValueViewT } from "@/types/database/diaries";

export function groupByInputId(list: EntryValueViewT[]): EntryValueViewT[][] {
	const grouped = list.reduce((acc, obj) => {
		const key = obj.input_id;

		if (!acc[key]) {
			acc[key] = [];
		}

		acc[key].push(obj);

		return acc;
	}, {});

	return Object.values(grouped);
}

type FilteredEntryValueViewT = EntryValueViewT & { input_key: string };

export function filterGraphableInputKeys(
	entryValues: EntryValueViewT[],
): FilteredEntryValueViewT[] {
	const graphableInputKeys = ["pain-slider", "glucose-slider"];
	const filtered = entryValues.filter((ev) =>
		graphableInputKeys.includes(ev?.input_key),
	);
	return filtered as FilteredEntryValueViewT[];
}

export function groupByInputKey(
	list: FilteredEntryValueViewT[],
): FilteredEntryValueViewT[][] {
	const grouped = list.reduce((acc, obj) => {
		const key = obj.input_key;

		if (!acc[key]) {
			acc[key] = [];
		}

		acc[key].push(obj);

		return acc;
	}, {});

	return Object.values(grouped);
}

export function processGraphEntryValues(
	entryValues: EntryValueViewT[],
): FilteredEntryValueViewT[][] {
	const filtered = filterGraphableInputKeys(entryValues);
	const grouped = groupByInputKey(filtered);
	return grouped;
}
