import { useNav } from "@/utils/nav";
import useAuthQuery from "../features/auth/api/useAuthQuery";
import useLocalStorage from "./useLocalStorage";
import usePrint from "./usePrint";
export const hooks = {
	useLocalStorage,
	usePrint,
	useAuthQuery,
	useNav,
};
