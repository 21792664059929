import { Outlet, createFileRoute } from "@tanstack/react-router";
import { diaryByIdQueryOptions } from "../../../features/diary/api/getDiaryById";

const PatientsDiaryIdPage = () => {
	return <Outlet />;
};

export const Route = createFileRoute("/_app/patients/$diaryId")({
	component: PatientsDiaryIdPage,
	loader: ({ context, params }) => {
		context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
	},
});
