import { ui } from "../../../components/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import * as z from "zod";
import {
	createUniqueInjectionOptions,
	toFilteredUniqueInjectionOptions,
} from "../utils/injectionOptions";
import useInjectionDetailsOptions from "../hooks/useInjectionDetailsOptions";
import Autocomplete from "../../../components/ui/autocomplete";
import Fuse from "fuse.js";
import { Label } from "../../../components/ui/label";
import { useHandleNewInjectionLocation } from "../api/handleNewInjectionLocation";
import { useAuth } from "../../../utils/auth";

const formSchema = z.object({
	orthopaedic_area: z.string().min(1, "Required"),
	injection_site: z.string().min(1, "Required"),
	injection_subsite: z.string().min(1, "A specific location is required"),
});

type Props = {
	defaultArea?: string;
	defaultSite?: string;
};

export function InjectionLocationForm({ defaultArea, defaultSite }: Props) {
	const auth = useAuth();
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			orthopaedic_area: defaultArea || "",
			injection_site: defaultSite || "",
		},
	});

	const { injectionLocations } = useInjectionDetailsOptions();

	const fetchAreaSuggestions = async (search: string) => {
		const options = createUniqueInjectionOptions(
			injectionLocations,
			"orthopaedic_area",
		).map(({ value }) => value);

		const fuse = new Fuse(options, {
			threshold: 0.3,
			distance: 100,
		});

		return search ? fuse.search(search).map((result) => result.item) : options;
	};

	const fetchSiteSuggestions = async (search: string) => {
		const options = toFilteredUniqueInjectionOptions(
			injectionLocations,
			"injection_site",
			"orthopaedic_area",
			form.watch("orthopaedic_area"),
		).map(({ value }) => value);

		const fuse = new Fuse(options, {
			threshold: 0.3,
			distance: 100,
		});

		return search ? fuse.search(search).map((result) => result.item) : options;
	};

	const fetchSubsiteSuggestions = async (search: string) => {
		const options = toFilteredUniqueInjectionOptions(
			injectionLocations,
			"injection_subsite",
			"injection_site",
			form.watch("injection_site"),
		).map(({ value }) => value);

		const fuse = new Fuse(options, {
			threshold: 0.3,
			distance: 100,
		});

		return search ? fuse.search(search).map((result) => result.item) : options;
	};

	const { mutate: handleNewInjectionLocation } =
		useHandleNewInjectionLocation();

	const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (
		values,
	) => {
		handleNewInjectionLocation({ user_id: auth.id, location: values });
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
				<div>
					<Label>Anatomic Area</Label>

					<Autocomplete
						fetchSuggestions={fetchAreaSuggestions}
						options={createUniqueInjectionOptions(
							injectionLocations,
							"orthopaedic_area",
						).map(({ value }) => value)}
						value={form.watch("orthopaedic_area")}
						onChange={(val) => form.setValue("orthopaedic_area", val)}
					/>
				</div>
				<div>
					<Label>Injection Site</Label>

					<Autocomplete
						value={form.watch("injection_site")}
						onChange={(val) => form.setValue("injection_site", val)}
						fetchSuggestions={fetchSiteSuggestions}
						options={toFilteredUniqueInjectionOptions(
							injectionLocations,
							"injection_site",
							"orthopaedic_area",
							form.watch("orthopaedic_area"),
						).map(({ value }) => value)}
					/>
				</div>
				<div>
					<Label>Injection Subsite</Label>
					<Autocomplete
						value={form.watch("injection_subsite")}
						onChange={(val) => form.setValue("injection_subsite", val)}
						fetchSuggestions={fetchSubsiteSuggestions}
						options={toFilteredUniqueInjectionOptions(
							injectionLocations,
							"injection_subsite",
							"injection_site",
							form.watch("injection_site"),
						).map(({ value }) => value)}
					/>
				</div>

				<div className="flex justify-end gap-2">
					<ui.DialogClose>
						<ui.Button variant="outline" onClick={() => form.reset()}>
							Cancel
						</ui.Button>
					</ui.DialogClose>

					<ui.DialogClose>
						<ui.Button type="submit">Submit</ui.Button>
					</ui.DialogClose>
				</div>
			</form>
		</ui.Form>
	);
}

export default InjectionLocationForm;
