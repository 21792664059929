import { supabaseClient } from "@/lib/supabase";
import type { NewDiaryTemplate } from "@/types/database/diaries";
import { useQuery } from "@tanstack/react-query";

export const selectNewDiaryTemplates = async () => {
	const { data, error } = await supabaseClient
		.from("new_diary_templates")
		.select("*")
		.order("updated_at", { ascending: false });

	if (error) throw error;
	return data as NewDiaryTemplate["Row"][];
};

export const newDiaryTemplatesQueryKey = "new_diary_templates";

export const newDiaryTemplateQueryOptions = () => {
	return {
		queryKey: [newDiaryTemplatesQueryKey],
		queryFn: selectNewDiaryTemplates,
	};
};

export const useNewDiaryTemplates = () => {
	return useQuery(newDiaryTemplateQueryOptions());
};
