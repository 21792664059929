import React from "react";
import { Copy } from "../../../components/common/Copy";
import EditDialog from "../../../components/common/EditDialog";

const EditHospitalNumberDialog = ({
	trackerId,
	hospitalNumber,
}: { trackerId: string; hospitalNumber: string | null }) => {
	return (
		<EditDialog
			key={trackerId}
			editable={true}
			locked={false}
			show={true}
			label="Hospital Number"
			info={
				<Copy
					id={`copy-${hospitalNumber}`}
					value={hospitalNumber ?? "No Hospital Number"}
				>
					{hospitalNumber ?? "No Hospital Number"}
				</Copy>
			}
		>
			<p>Hosp num form</p>
		</EditDialog>
	);
};

export default EditHospitalNumberDialog;
