import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";

import Spinner from "@/components/common/Spinner";
import { Button, buttonVariants } from "@/components/ui/button";
import { useDub } from "../api/useDub";
import { useNavigateAndPrint } from "../../previews/hooks/useNavigateAndPrint";
import { MoreVertical, Printer, Pencil, Trash2 } from "lucide-react";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";

interface Props {
	url: string;
	title?: string;
	description?: string;
	children?: React.ReactNode;
	onDelete: () => void;
	onEdit: () => void;
	className?: string;
}

const NewDiaryTemplateDubQrCode = ({
	url,
	title,
	description,
	children,
	onDelete,
	onEdit,
	className,
}: Props) => {
	const { data, isFetching } = useDub(url);
	const { navigateAndPrint } = useNavigateAndPrint();

	return (
		<Card className={cn("flex flex-col justify-between", className)}>
			<CardHeader>
				<div className="flex justify-between items-start">
					<div className="flex flex-col gap-2">
						<CardTitle className="text-center">{title ?? "QR Code"}</CardTitle>
						<CardDescription className="text-center">
							{description ?? "Scan the QR code to Join"}
						</CardDescription>
					</div>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="outline" size="icon">
								<MoreVertical size={20} />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent className="min-w-xs" align="end">
							<DropdownMenuItem
								onClick={() =>
									navigateAndPrint({
										to: "/preview/invite/qr-code",
										search: { qrCode: data?.qrCode, title, description },
									})
								}
							>
								<Printer className="mr-2" size={20} />
								Print
							</DropdownMenuItem>
							<DropdownMenuItem onClick={onEdit}>
								<Pencil className="mr-2" size={20} />
								Edit
							</DropdownMenuItem>
							<DropdownMenuSeparator />
							<DropdownMenuItem onClick={onDelete} className="text-red-600">
								<Trash2 className="mr-2" size={20} />
								Delete
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</CardHeader>
			<CardContent>
				{children}
				<button
					type="button"
					onClick={onEdit}
					className="flex justify-center items-center mx-auto"
				>
					{isFetching ? (
						<Spinner className="w-16 h-16" />
					) : (
						<img
							className="mx-auto p-4 w-[300px] h-[300px]"
							src={data?.qrCode}
							alt="QR Code"
						/>
					)}
				</button>
			</CardContent>
			<CardFooter>
				{data?.shortLink && (
					<a
						className={buttonVariants({ variant: "link" })}
						target="_blank"
						href={data.shortLink}
						rel="noreferrer"
					>
						Go to Link
					</a>
				)}
			</CardFooter>
		</Card>
	);
};

export default NewDiaryTemplateDubQrCode;
