import { supabaseClient } from "@/lib/supabase";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import type { UuidT } from "@/types/zod/auth";
import { injection_locations_key } from "./selectInjectionLocations";

interface InjectionLocation {
	orthopaedic_area: string;
	injection_site: string;
	injection_subsite: string;
}

interface Props {
	user_id: string;
	location: InjectionLocation;
}

export async function handleNewInjectionLocation(props: Props) {
	const { data, error } = await supabaseClient.rpc(
		"handle_new_injection_location",
		{
			p_user_id: props.user_id,
			p_orthopaedic_area: props.location.orthopaedic_area,
			p_injection_site: props.location.injection_site,
			p_injection_subsite: props.location.injection_subsite,
		},
	);

	if (error) throw error;
	return data as UuidT;
}

export const useHandleNewInjectionLocation = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ["new-injection-location"],
		mutationFn: handleNewInjectionLocation,
		onError: (error) => {
			toast.error("Failed to create injection location");
			console.error(error);
		},
		onSuccess: (locationId) => {
			toast.success("New injection location created");
			return locationId;
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: injection_locations_key });
		},
	});
};
