import {
	Briefcase,
	CandlestickChart,
	ClipboardCheck,
	Code2,
	Cpu,
	Ear,
	HeartHandshake,
	KeyRound,
	LayoutDashboard,
	PersonStanding,
	Target,
	UserCheck2,
} from "lucide-react";

import Guy from "../assets/Guy.jpg";
import Milo from "../assets/Milo.png";
import Sam from "../assets/Sam.png";

export const content = [
	{
		name: "Guy Solan",
		role: "Designer and Founder",
		description: (
			<p>
				Guy’s focus is designing software that is usable, accessible and most
				importantly useful.
			</p>
		),
		image: Guy,
		skills: [
			{
				name: "User Centered Design",
				icon: <HeartHandshake size={32} strokeWidth={1.5} />,
			},
			{
				name: "Accessiblity and Usablity",
				icon: <PersonStanding size={32} strokeWidth={1.5} />,
			},
			{
				name: "Medical Device Regulations",
				icon: <ClipboardCheck size={32} strokeWidth={1.5} />,
			},
			{
				name: "UI & UX Design",
				icon: <LayoutDashboard size={32} strokeWidth={1.5} />,
			},
		],
	},
	{
		name: "Milo Elliott",
		role: "Development and Security",
		description: (
			<p>Milo leads data security, backend development, analytics and AI.</p>
		),
		image: Milo,
		skills: [
			{
				name: "Progamming",
				icon: <Code2 size={32} strokeWidth={1.5} />,
			},
			{
				name: "Statstics and Analytics",
				icon: <CandlestickChart size={32} strokeWidth={1.5} />,
			},
			{
				name: "Machine Learning & AI",
				icon: <Cpu size={32} strokeWidth={1.5} />,
			},
			{
				name: "Cyber Security",
				icon: <KeyRound size={32} strokeWidth={1.5} />,
			},
		],
	},
	{
		name: "Sam Perkins",
		role: "Doctor and Business Lead",
		description: (
			<p>
				Sam works to improve every customer’s experience; building the business
				one new clinician at a time.
			</p>
		),
		image: Sam,
		skills: [
			{
				name: "Client Experience",
				icon: <UserCheck2 size={32} strokeWidth={1.5} />,
			},
			{
				name: "Customer Service",
				icon: <Ear size={32} strokeWidth={1.5} />,
			},
			{
				name: "Business Strategy",
				icon: <Target size={32} strokeWidth={1.5} />,
			},
			{
				name: "Partnerships",
				icon: <Briefcase size={32} strokeWidth={1.5} />,
			},
		],
	},
];
