import { ui } from "@/components/ui";
import { Link } from "@tanstack/react-router";

const GoToPatientsCard = () => {
	return (
		<ui.Card className="mb-4 bg-indigo-100 border-indigo-300">
			<ui.CardHeader>
				<ui.CardTitle>See your Patients</ui.CardTitle>
				<ui.CardDescription className="text-md">
					Monitor your patients' progress and manage their diaries
				</ui.CardDescription>
			</ui.CardHeader>
			<ui.CardFooter>
				<ui.Button size="md" asChild>
					<Link to="/patients">Go to Patients</Link>
				</ui.Button>
			</ui.CardFooter>
		</ui.Card>
	);
};

export default GoToPatientsCard;
