import { Button, buttonVariants } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from "@/components/ui/command";

import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import * as changeCase from "change-case";
import { CheckIcon, ChevronsUpDown, Lock, Plus, X } from "lucide-react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ScrollArea } from "../ui/scroll-area";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";
import type { OptionT } from "./Select";

interface Props {
	label?: string;
	name: string;
	description?: string;
	options: OptionT[];
	disabled?: boolean;
	autoFocus?: boolean;
	clearable?: boolean;
	addOption?: React.ReactNode;
	placeholder?: string;
}

function ComboBox({
	options,
	autoFocus,
	placeholder,
	disabled,
	label,
	name,
	description,
	clearable,
	addOption,
}: Props) {
	const form = useFormContext();
	const [open, setOpen] = React.useState(false);
	const [search, setSearch] = React.useState("");

	return (
		<FormField
			control={form.control}
			name={name}
			render={({ field }) => (
				<FormItem className="w-full">
					{label && <FormLabel>{label}</FormLabel>}
					{description && <FormDescription>{description}</FormDescription>}
					<Popover open={open} onOpenChange={setOpen}>
						<div className="flex items-center">
							<PopoverTrigger
								data-testid={`${changeCase.kebabCase(name)}-trigger`}
								autoFocus={autoFocus}
								disabled={disabled}
								asChild
							>
								<FormControl>
									<Button
										variant="outline"
										role="combobox"
										size="sm"
										className={cn(
											"h-12 w-full min-w-[250px] justify-between rounded-md bg-white bg-opacity-100 pl-3 pr-1 text-sm hover:bg-slate-100",
											!field.value && "text-slate-500",
										)}
									>
										{field.value
											? options.find((o) => o.value === field.value)?.label
											: placeholder ?? "Select"}

										<div className="flex flex-row items-center">
											{disabled ? (
												<Button
													tabIndex={-1}
													variant="ghost"
													size="icon"
													type="button"
													disabled
												>
													<Lock size={16} />
												</Button>
											) : (
												<Button
													tabIndex={-1}
													variant="ghost"
													size="icon"
													type="button"
												>
													<ChevronsUpDown size={16} />
												</Button>
											)}
										</div>
									</Button>
								</FormControl>
							</PopoverTrigger>
							{clearable && field.value && (
								<Button
									variant="outline"
									size="icon"
									type="button"
									className="bg-white bg-opacity-100 ml-2 rounded-md w-12 h-12 text-slate-600"
									onClick={() => {
										form.setValue(name, null);
									}}
								>
									<X size={16} />
									<span className="sr-only">Clear</span>
								</Button>
							)}
						</div>
						<PopoverContent className="p-0 w-full">
							<Command>
								<CommandInput
									placeholder="Search..."
									className="border-none focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0 h-9"
									value={search}
									onValueChange={setSearch}
								/>
								<CommandEmpty>No options found.</CommandEmpty>
								<CommandGroup>
									<ScrollArea className="relative max-h-sm">
										{options.map((o) => (
											<CommandItem
												data-testid={`${changeCase.kebabCase(
													o.label ?? "",
												)}-option`}
												role="option"
												value={o.label}
												key={o.value}
												onSelect={() => {
													form.setValue(name, o.value);
													setOpen(false);
												}}
											>
												{o.label}
												<CheckIcon
													className={cn(
														"ml-auto h-4 w-4",
														o.value === field.value
															? "opacity-100"
															: "opacity-0",
													)}
												/>
											</CommandItem>
										))}
										{addOption && (
											<>
												<div className="h-12" />
												<Dialog>
													<DialogTrigger
														className={cn(
															buttonVariants({
																size: "sm",
																variant: "outline",
															}),
															"flex absolute bottom-0 left-0 gap-2 mt-1 w-full",
														)}
													>
														<Plus size={20} />
														Add an Option
													</DialogTrigger>
													<DialogContent>
														<DialogHeader>
															<DialogTitle>Add an Option</DialogTitle>
														</DialogHeader>
														{addOption}
													</DialogContent>
												</Dialog>
											</>
										)}
									</ScrollArea>
								</CommandGroup>
							</Command>
						</PopoverContent>
					</Popover>

					<FormMessage />
				</FormItem>
			)}
		/>
	);
}

export default ComboBox;
