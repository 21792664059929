import { columns } from "@/features/patients/features/overview/table/components/Columns";
import type { DiaryViewT } from "@/types/database/diaries";
import { useNavigate } from "@tanstack/react-router";
import {
	type ColumnFiltersState,
	type SortingState,
	type VisibilityState,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import * as React from "react";

interface Props {
	diaries: DiaryViewT[];
}
function useDiariesTable({ diaries }: Props) {
	// @ts-ignore
	const navigate = useNavigate();

	const [sorting, setSorting] = React.useState<SortingState>([
		{ id: "diary_status", desc: true },
	]);

	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
		[],
	);
	const [columnVisibility, setColumnVisibility] =
		React.useState<VisibilityState>({});
	const table = useReactTable({
		data: diaries,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
		},
	});

	// Calculate pagination details
	const { pageSize, pageIndex } = table.getState().pagination;
	const totalRows = diaries.length;
	const start = pageIndex * pageSize + 1;
	const end = Math.min((pageIndex + 1) * pageSize, totalRows);
	const paginationRange = {
		start,
		end,
		total: totalRows,
	};

	return { table, paginationRange };
}

export default useDiariesTable;
