import { ui } from "@/components/ui";
import { trackerComponents as tc } from "@/features/trackers/components";
import useExtractTracker from "@/features/trackers/hooks/useExtractTracker";
import { useAuth } from "@/utils/auth";
import { createFileRoute } from "@tanstack/react-router";
import Group from "@/components/common/Group";
import { Link } from "lucide-react";

const EditTracker = () => {
	const { diaryId, trackerId } = Route.useParams();
	const tracker = useExtractTracker(diaryId, trackerId);

	return (
		<section>
			<Group title={tracker.tracker_name}>
				<tc.EditTracker tracker={tracker} />
			</Group>
		</section>
	);
};

export const Route = createFileRoute(
	"/_app/diary/$diaryId/edit/tracker/$trackerId/",
)({
	component: EditTracker,
});
