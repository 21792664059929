import type { StatefulDiaryT } from "@/types/database/diaries";
import { AlertCircle } from "lucide-react";
import { useMissingDetails } from "../hooks/useMissingDetails";
import Call from "@/components/common/Call";
import { Button } from "../../../components/ui/button";
import { Link } from "@tanstack/react-router";

interface DiaryCardProps {
	diary: StatefulDiaryT;
}

const MissingDiaryDetailsCall: React.FC<DiaryCardProps> = ({ diary }) => {
	const { missingDetails, hasMissingDetails } = useMissingDetails(diary);

	if (!hasMissingDetails) {
		return null;
	}

	return (
		<Call
			icon={<AlertCircle size={20} />}
			words="Missing Information"
			variant="warning"
			size="sm"
			className="pb-4"
		>
			<ul className="space-y-0.5">
				{missingDetails?.trackers?.missingDate?.map((tracker) => (
					<li key={tracker.tracker_id}>• Injection Date</li>
				))}
				{missingDetails?.trackers?.missingHospitalNumber?.map((tracker) => (
					<li key={tracker.tracker_id}>• Hospital Number</li>
				))}
				{missingDetails?.injections?.missingSide?.map((injection) => (
					<li key={injection.injection_id}>• Side of Procedure</li>
				))}
				{missingDetails?.injections?.missingOrthopaedicArea?.map(
					(injection) => (
						<li key={injection.injection_id}>• Anatomic Area</li>
					),
				)}
			</ul>
			<Button className="bg-amber-600 hover:bg-amber-700" size="sm" asChild>
				<Link to="/diary/$diaryId/edit" params={{ diaryId: diary.diary_id }}>
					Complete Details
				</Link>
			</Button>
		</Call>
	);
};

export default MissingDiaryDetailsCall;
