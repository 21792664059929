import { Link, useRouter } from "@tanstack/react-router";

import { colors } from "@/assets/colors";
import Warning from "@/assets/openmoji/Warning";
import { useLogoutMutation } from "@/features/auth/api/useLogoutMutation";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { commonComponents as cc } from "../common";
import Description from "../common/Description";
import { ui } from "../ui";
interface Props {
	title?: string;
	error: Error;
	reset: () => void;
}

const ErrorPage = (props: Props) => {
	Sentry.captureException(props);
	const router = useRouter();
	const logout = useLogoutMutation();

	return (
		<section>
			<cc.Title words={props.title ?? "Whoops, this one is our fault"} />
			<Warning fill={colors["amber-400"]} className="flex mx-auto w-32 h-32" />
			<div className="flex flex-col justify-center items-center gap-4 mx-auto p-4 w-full text-center">
				<Description> {props.error?.message as string}</Description>
				<Description className="text-md text-slate-800">
					Please let us know what happened at{" "}
					<a
						className="text-indigo-600"
						href="mailto:support@patient-watch.com"
					>
						support@patient-watch.com
					</a>
				</Description>
				<ui.Button
					variant="secondary"
					onClick={() => {
						props.reset();
						router.invalidate();
					}}
				>
					Retry
				</ui.Button>
				<ui.Button>
					<Link onClick={() => logout.mutate()} to="/">
						Restart
					</Link>
				</ui.Button>
			</div>
		</section>
	);
};

export default ErrorPage;
