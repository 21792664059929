import type { StatefulDiaryT } from "@/types/database/diaries";

export function useMissingDetails(diary: StatefulDiaryT) {
	const missingDetails = {
		trackers: {
			missingDate: diary.trackers_view?.filter(
				(tracker) => tracker?.reference_date === null,
			),
			missingHospitalNumber: diary.trackers_view?.filter(
				(tracker) => tracker?.hospital_number === null,
			),
		},
		injections: {
			missingSide:
				diary.events_view
					?.flatMap((event) =>
						event?.injections_view?.filter((injection) =>
							injection?.injection_details?.some(
								(detail) => detail?.side === null,
							),
						),
					)
					.filter(Boolean) ?? [],
			missingOrthopaedicArea:
				diary.events_view
					?.flatMap((event) =>
						event?.injections_view?.filter((injection) =>
							injection?.injection_details?.some(
								(detail) => detail?.orthopaedic_area === null,
							),
						),
					)
					.filter(Boolean) ?? [],
		},
	};

	const hasMissingDetails =
		(missingDetails.trackers.missingDate?.length ?? 0) > 0 ||
		(missingDetails.trackers.missingHospitalNumber?.length ?? 0) > 0 ||
		(missingDetails.injections.missingSide?.length ?? 0) > 0 ||
		(missingDetails.injections.missingOrthopaedicArea?.length ?? 0) > 0;

	return {
		missingDetails,
		hasMissingDetails,
	};
}
