import type { DiaryViewT } from "@/types/database/diaries";
import type { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import ReadIcon from "../../../read-unread/components/ReadIcon";
import ReadUnreadIcons from "../../../read-unread/components/ReadUnreadIcons";
import SelectMissingState from "./SelectMissingState";
import SortButton from "./SortButton";
import State from "./StateCell";
import Cell from "./TableCell";
import TableRowMenu from "./TableRowMenu";

export const columns: ColumnDef<DiaryViewT>[] = [
	{
		id: "select",
		accessorKey: "is_read",
		header: ({ column }) => (
			<SortButton
				disabled={true}
				className="pr-0"
				words={<ReadIcon />}
				column={column}
			/>
		),
		cell: ({ row }) => <ReadUnreadIcons isRead={row.original.is_read} />,
		enableSorting: true,
		enableHiding: false,
	},

	{
		accessorKey: "diary_status",
		header: ({ column }) => <SortButton words="Diary Status" column={column} />,
		cell: ({ row }) => (
			<State diaryId={row.original.diary_id} state={row.original.state} />
		),
	},
	{
		id: "has_incomplete_events",
		accessorKey: "has_incomplete_events",
		header: ({ column }) => (
			<SortButton words="Diary Details" column={column} />
		),
		cell: ({ row }) => <SelectMissingState diary={row.original} />,
		enableSorting: true,
		enableHiding: false,
	},
	// {
	// 	accessorKey: 'nhs_number',
	// 	header: ({ column }) => {
	// 		return <SortButton words="NHS number" column={column} />;
	// 	},
	// 	cell: ({ row }) => (
	// 		<Cell diaryId={row.original.diary_id} className="">
	// 			{row.original.patients?.profiles?.nhs_number ?? 'Not Defined'}
	// 		</Cell>
	// 	),
	// },
	{
		accessorKey: "name",
		header: ({ column }) => {
			return <SortButton words="Patient Name" column={column} />;
		},
		cell: ({ row }) => (
			<Cell diaryId={row.original.diary_id} className="text-nowrap">
				{row.original.patient_info?.patient_name ?? "Not Defined"}
			</Cell>
		),
	},
	{
		accessorKey: "hospital_numbers",
		header: ({ column }) => {
			return (
				<SortButton
					disabled={true}
					words="Hospital Number(s)"
					column={column}
				/>
			);
		},
		cell: ({ row }) => (
			<Cell diaryId={row.original.diary_id} className="text-nowrap">
				{row.original.hospital_numbers?.join(", ") ?? "Not Defined"}
			</Cell>
		),
	},

	{
		accessorKey: "start_date",
		header: ({ column }) => {
			return <SortButton words="Start Date" column={column} />;
		},
		cell: ({ row }) => (
			<Cell diaryId={row.original.diary_id} className="">
				{formatDate(row.getValue("start_date"))}
			</Cell>
		),
	},
	// {
	// 	accessorKey: 'end_date',
	// 	header: ({ column }) => {
	// 		return <SortButton words="End Date" column={column} />;
	// 	},
	// 	cell: ({ row }) => (
	// 		<Cell diaryId={row.original.diary_id} className="">
	// 			{formatDate(row.getValue('end_date'))}
	// 		</Cell>
	// 	),
	// },
	{
		accessorKey: "date_of_birth",
		header: ({ column }) => {
			return (
				<SortButton disabled={true} words="Date of Birth" column={column} />
			);
		},
		cell: ({ row }) => (
			<Cell diaryId={row.original.diary_id} className="">
				{row.original.patient_info?.date_of_birth ?? "Not Defined"}
			</Cell>
		),
	},
	{
		accessorKey: "diary_name",
		header: ({ column }) => {
			return <SortButton words="Diary Name" column={column} />;
		},
		cell: ({ row }) => (
			<Cell diaryId={row.original.diary_id} className="">
				{row.original.diary_name}
			</Cell>
		),
	},
	{
		accessorKey: "organisation_name",
		header: ({ column }) => {
			return (
				<SortButton disabled={true} words="Organisation" column={column} />
			);
		},
		cell: ({ row }) => (
			<Cell diaryId={row.original.diary_id} className="">
				{row.original.organisation_name ?? ""}
			</Cell>
		),
	},
	// {
	// 	accessorKey: "diary_type",
	// 	header: ({ column }) => {
	// 		return <SortButton disabled={true} words="Diary Type" column={column} />;
	// 	},
	// 	cell: ({ row }) => (
	// 		<Cell diaryId={row.original.diary_id} className="">
	// 			{showDiaryType(row.original.diary_type as string) ?? ""}
	// 		</Cell>
	// 	),
	// },
	{
		id: "actions",
		enableHiding: false,
		cell: ({ row }) => {
			return <TableRowMenu diary={row.original} />;
		},
	},
];

const formatDate = (dateString: string) => {
	return dateString ? dayjs(dateString).format("DD MMM YYYY") : "";
};
