import { cn } from "@/lib/utils";
import type { ExtendedInjectionViewT } from "@/types/database/events";
import { Crosshair, TestTube2 } from "lucide-react";
import { warningColors } from "../../../utils/warningColors";
interface Props {
	injection: ExtendedInjectionViewT;
}
const InjectionSummary = ({ injection }: Props) => {
	return (
		<div className={cn("text-sm text-slate-700 dark:text-slate-300")}>
			<p>
				Injection referred by{" "}
				<span
					className={cn(
						"text-semibold",
						warningColors(!injection?.referring_clinician_id),
					)}
				>
					{injection?.referring_clinician_id
						? injection?.referring_clinician_name
						: "an unknown clinician"}
				</span>{" "}
				and performed by{" "}
				<span
					className={cn(
						"text-semibold",
						warningColors(!injection?.injecting_clinician_id),
					)}
				>
					{injection?.injecting_clinician_id
						? injection?.injecting_clinician_name
						: "an unknown clinician"}
				</span>{" "}
				in{" "}
				<span
					className={cn(
						"text-semibold",
						warningColors(!injection?.injection_method),
					)}
				>
					{injection?.injection_method
						? injection?.injection_method
						: "an unknown location"}
				</span>
				.
			</p>
			{injection.injection_details?.map((idv) => (
				<p
					key={idv.injection_detail_id}
					className={cn("flex flex-col gap-y-1 ml-2")}
				>
					<span
						className={cn(
							"flex gap-2 items-center justify-start ",
							warningColors(!idv.injection_location),
						)}
					>
						<Crosshair size={20} />
						{idv.injection_location ?? "Missing Injection Location"}
					</span>
					<span
						className={cn(
							"flex gap-2 items-center justify-start",
							warningColors(!idv.injection_content),
						)}
					>
						<TestTube2 size={20} />
						{idv.injection_content ?? "Missing Injection Content"}
					</span>
				</p>
			))}
		</div>
	);
};

export default InjectionSummary;
