import { commonComponents as cc } from "@/components/common";
import DiaryTitle from "@/features/diaries/components/DiaryTitle";
import {
	diaryByIdQueryOptions,
	useDiaryByIdQuery,
} from "@/features/diary/api/getDiaryById";
import DiaryViewTabs from "@/features/diary/components/DiaryViewTabs";
import { Outlet, createFileRoute } from "@tanstack/react-router";
import ErrorPage from "@/components/pages/ErrorPage";
import MissingDiaryDetailsCall from "@/features/diaries/components/MissingDiaryDetailsCall";
const Home = () => {
	const { diaryId } = Route.useParams();
	const { data: diary, isFetching } = useDiaryByIdQuery(diaryId);

	return (
		<section className="md-width">
			<DiaryTitle diary={diary} editing={false} />
			<MissingDiaryDetailsCall diary={diary} />
			<div className="mx-4 my-4 w-md">
				<DiaryViewTabs />
			</div>
			{isFetching ? (
				<div className="flex flex-col justify-center items-center">
					<cc.Spinner className="mx-auto my-8 w-12 h-12" />
					<cc.Description>Loading latest diary information</cc.Description>
				</div>
			) : (
				<Outlet />
			)}
		</section>
	);
};

export const Route = createFileRoute("/_app/diary/$diaryId/home")({
	component: Home,
	errorComponent: ({ error, reset }) => (
		<ErrorPage error={error as Error} reset={reset} />
	),
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(
			diaryByIdQueryOptions(params.diaryId),
		);
		return { diary };
	},
});
