import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { supabaseClient } from "@/lib/supabase";

import type { NewDiaryTemplate } from "@/types/database/diaries";

export const upsertNewDiaryTemplate = async (
	template: NewDiaryTemplate["Insert"],
) => {
	const { data, error } = await supabaseClient
		.from("new_diary_templates")
		.upsert(template)
		.select()
		.single();

	if (error) throw error;
	return data;
};

export const useUpsertNewDiaryTemplate = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: upsertNewDiaryTemplate,
		onSuccess: () => {
			toast.success("Template saved successfully");
			queryClient.invalidateQueries({ queryKey: ["new_diary_templates"] });
		},
		onError: (error) => {
			toast.error("Failed to save template");
			console.error("Template save error:", error);
		},
	});
};
