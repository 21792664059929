import { supabaseClient } from "@/lib/supabase";
import type { StateKeyT, TabKeyT } from "@/types/database/diaries";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { toast } from "sonner";

import { useRefetchPatientsData } from "../../overview/table/hooks/useRefetchPatientsData";
import type { Props as ActionDetailSchemaT } from "../components/ActionEmail";

type TakeActionT = {
	diary_id: string;
	action_id: string;
	tab: TabKeyT;
	state: StateKeyT;
};
export async function takeAction(takeActionData: ActionDetailSchemaT) {
	const { data, error } = await supabaseClient.functions.invoke(
		"public-take-action",
		{
			body: takeActionData,
		},
	);

	if (error) throw error;

	return data as TakeActionT;
}

export const useTakeAction = () => {
	const navigate = useNavigate();
	const refetchPatientsData = useRefetchPatientsData();
	return useMutation({
		mutationFn: async (data: ActionDetailSchemaT) => {
			return await takeAction(data);
		},
		onError: (error) => {
			toast.error(`${error.message}`);
		},
		onSuccess: (data) => {
			toast.success("Action taken successfully");
			navigate({
				to: "/patients/$diaryId",
				params: { diaryId: data.diary_id },
				search: { tab: data.tab },
			});
		},
		onSettled: () => {
			refetchPatientsData();
		},
	});
};

export default useTakeAction;
