import "react-phone-number-input/style.css";

import { formComponents as fc } from "@/components/form";
import { ui } from "@/components/ui";
import { Form } from "@/components/ui/form";
import useUserMutation from "@/features/profile/api/useUserMutation";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import * as z from "zod";

const formSchema = z.object({
	phone: z
		.string()
		.min(1, "Phone number required")
		.refine(
			(value) => isPossiblePhoneNumber(value),
			"Not a valid phone number",
		),
});

interface Props {
	userId: string;
	phone: string | null;
	onSuccess?: () => void;
}
const PhoneForm = (props: Props) => {
	type FormSchemaType = z.infer<typeof formSchema>;

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			phone: props.phone ?? "",
		},
	});

	useEffect(() => {
		form.setValue("phone", `+${props.phone ?? ""}`);
	}, [props.phone]);

	const profileMutation = useUserMutation(props.userId);

	const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
		await profileMutation.mutate(
			{ phone: formData.phone.replace("+", "") },
			{ onSuccess: props.onSuccess },
		);
	};

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.Phone autoFocus={true} />
				<fc.SaveButton />
			</form>
		</Form>
	);
};

export default PhoneForm;
