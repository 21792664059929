import { commonComponents as cc } from "@/components/common";
import { ui } from "@/components/ui";
import { watches } from "@/definitions/diaryTypes";
import type { Watch } from "@/definitions/diaryTypes";
import { cn } from "@/lib/utils";
import type { StatefulDiaryT } from "@/types/database/diaries";
import { Link } from "@tanstack/react-router";
import dayjs from "dayjs";
import { BellRing } from "lucide-react";
import type React from "react";
import { useAuth } from "../../../utils/auth";
import MissingDiaryDetails from "./MissingDiaryDetails";
import { useMissingDetails } from "../hooks/useMissingDetails";

interface DiaryCardProps {
	diary: StatefulDiaryT;
}

//
const DiaryOverviewCard: React.FC<DiaryCardProps> = ({ diary }) => {
	const watchType = diary.diary_type?.toUpperCase() as Watch;
	const watch = watches[watchType];
	const auth = useAuth();

	const { hasMissingDetails } = useMissingDetails(diary);

	return (
		<ui.Card className={cn("mb-4 text-sm overflow-hidden relative")}>
			<ui.CardHeader className="pb-0">
				<div className="flex justify-between items-start w-full">
					<h3 className="flex justify-between items-center gap-x-2 my-auto w-full font-medium text-lg">
						{watch.name}
						{diary.tracker_due && (
							<div className="animate-big-wiggle">
								<BellRing />
							</div>
						)}
					</h3>
				</div>
			</ui.CardHeader>
			<ui.CardContent className="space-y-3">
				<h2 className="font-medium text-slate-800 text-xl dark:text-slate-200">
					{diary.diary_name}
				</h2>
				<h4 className="font-normal">
					{diary?.start_date
						? `Start Date: ${dayjs(diary?.start_date).format("DD MMM YYYY")}`
						: `Diary Created: ${dayjs(diary?.created_at).format(
								"DD MMM YYYY",
							)}`}
				</h4>
				<div className="flex flex-wrap gap-2 mt-2">
					<ui.Badge variant="secondary">{diary.organisation_name}</ui.Badge>
					{diary.patient_id !== auth.id && (
						<ui.Badge variant="outline">
							{diary.patient_info.patient_name}
						</ui.Badge>
					)}
				</div>
				<MissingDiaryDetails diary={diary} />
				{hasMissingDetails && (
					<ui.Button variant="secondary" asChild>
						<Link
							to="/diary/$diaryId/edit"
							params={{ diaryId: diary.diary_id }}
						>
							Complete Details
						</Link>
					</ui.Button>
				)}
			</ui.CardContent>
			<ui.CardFooter className="flex justify-start items-center gap-2">
				<ui.Button asChild>
					<Link
						to="/diary/$diaryId/home/new"
						params={{ diaryId: diary.diary_id }}
					>
						Next
					</Link>
				</ui.Button>
			</ui.CardFooter>
		</ui.Card>
	);
};

export default DiaryOverviewCard;
