import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { supabaseClient } from "@/lib/supabase";

export const deleteNewDiaryTemplate = async (templateId: string) => {
	const { error } = await supabaseClient
		.from("new_diary_templates")
		.delete()
		.eq("template_id", templateId);

	if (error) throw error;
	return templateId;
};

export const useDeleteNewDiaryTemplate = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: deleteNewDiaryTemplate,
		onSuccess: () => {
			toast.success("Template deleted successfully");
			queryClient.invalidateQueries({ queryKey: ["new_diary_templates"] });
		},
		onError: (error) => {
			toast.error("Failed to delete template");
			console.error("Template delete error:", error);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["new_diary_templates"] });
		},
	});
};
