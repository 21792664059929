import { ui } from "@/components/ui";
import {
	ArrowUpCircle,
	FolderDown,
	ShieldQuestion,
	UserPlus2,
} from "lucide-react";

const PatientsButtons = () => {
	return (
		<div className="flex gap-x-1 items-center">
			<ui.TooltipProvider>
				<ui.Tooltip delayDuration={100}>
					<ui.TooltipTrigger>
						<ui.Button variant="outline" size="icon">
							<FolderDown />
						</ui.Button>
					</ui.TooltipTrigger>
					<ui.TooltipContent>
						<p>Download Diaries CSV</p>
					</ui.TooltipContent>
				</ui.Tooltip>
			</ui.TooltipProvider>
			<ui.TooltipProvider>
				<ui.Tooltip delayDuration={100}>
					<ui.TooltipTrigger>
						<ui.Button variant="outline" size="icon">
							<ShieldQuestion />
						</ui.Button>
					</ui.TooltipTrigger>
					<ui.TooltipContent>
						<p>Help</p>
					</ui.TooltipContent>
				</ui.Tooltip>
			</ui.TooltipProvider>
			<ui.TooltipProvider>
				<ui.Tooltip delayDuration={100}>
					<ui.TooltipTrigger>
						<ui.Button variant="outline" size="icon">
							<UserPlus2 />
						</ui.Button>
					</ui.TooltipTrigger>
					<ui.TooltipContent>
						<p>Add Patient</p>
					</ui.TooltipContent>
				</ui.Tooltip>
			</ui.TooltipProvider>
			<ui.TooltipProvider>
				<ui.Tooltip delayDuration={100}>
					<ui.TooltipTrigger>
						<ui.Button variant="outline" size="icon">
							<ArrowUpCircle />
						</ui.Button>
					</ui.TooltipTrigger>
					<ui.TooltipContent>
						<p>Download Diaries CSV</p>
					</ui.TooltipContent>
				</ui.Tooltip>
			</ui.TooltipProvider>
		</div>
	);
};

export default PatientsButtons;
