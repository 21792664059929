import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

export type ButtonVariant =
	| "default"
	| "destructive"
	| "outline"
	| "secondary"
	| "ghost"
	| "link";

export type ButtonSize = "default" | "sm" | "md" | "lg" | "xl" | "icon";

export interface ButtonVariants {
	variant: {
		[key in ButtonVariant]: string;
	};
	size: {
		[key in ButtonSize]: string;
	};
}

const buttonVariants = cva(
	"inline-flex justify-center items-center gap-x-[6px] disabled:opacity-40 backdrop-blur-lg rounded-md focus-visible:ring-2 focus-visible:ring-slate-950 dark:focus-visible:ring-slate-300 ring-offset-white focus-visible:ring-offset-2 dark:ring-offset-slate-900 dark:ring-offset-slate-950 font-medium text-md whitespace-nowrap transition-colors disabled:pointer-events-none focus-visible:outline-none",
	{
		variants: {
			variant: {
				default:
					"bg-indigo-600 text-indigo-50 hover:bg-indigo-700 dark:bg-indigo-500 dark:text-white dark:hover:bg-indigo-500/90",
				destructive:
					"bg-red-500 text-red-50 hover:bg-red-600 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/90",
				outline:
					"border border-slate-300 text-slate-800 bg-opacity-50 bg-slate-50 hover:bg-slate-100 hover:text-slate-900 dark:border-slate-500 dark:bg-slate-900 dark:text-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-50",
				secondary:
					"bg-indigo-200 text-indigo-700 hover:text-indigo-800 hover:bg-indigo-300 dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-900/80",
				"destructive-secondary":
					"bg-red-200 text-red-700 hover:bg-red-300 dark:bg-red-800 dark:text-slate-50 dark:hover:bg-red-900/80",
				ghost:
					"bg-transparent hover:bg-slate-100 hover:text-slate-900 dark:hover:bg-slate-800 dark:text-slate-100 dark:hover:text-slate-50",
				link: "bg-transparent text-indigo-600 underline-offset-4 underline dark:text-indigo-50 disabled:text-slate-400 hover:bg-transparent",
				"destructive-link":
					"text-red-600 underline-offset-4 underline dark:text-red-50",
			},
			size: {
				default: "h-14 gap-2 rounded-lg px-10 w-full",
				md: "h-12 gap-2 px-6 py-2 w-fit",
				sm: "h-11 gap-1 text-sm rounded-md px-3 w-fit",
				lg: "h-14 gap-2 rounded-lg px-10  w-full",
				xl: "text-xl gap-2 h-16 rounded-md px-16 w-full",
				icon: "h-10 w-10 rounded-md p-0",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : "button";
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				{...props}
			/>
		);
	},
);
Button.displayName = "Button";

export { Button, buttonVariants };
