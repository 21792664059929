import { formComponents as fc } from "@/components/form";
import { ui } from "@/components/ui";
import useExtractTracker from "@/features/trackers/hooks/useExtractTracker";
import { useTrackerUpdate } from "@/features/trackers/hooks/useTrackerUpdate";
import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as z from "zod";

interface Props {
	diaryId: string;
	trackerId: string;
}

const formSchema = z.object({
	tracker_name: z.string().min(1, "Tracker name is required"),
});

type FormSchemaType = z.infer<typeof formSchema>;

const TrackerNameForm = ({ diaryId, trackerId }: Props) => {
	const tracker = useExtractTracker(diaryId, trackerId);
	const { updateTrackerName } = useTrackerUpdate(diaryId, trackerId);

	const form = useForm<FormSchemaType>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			tracker_name: tracker?.tracker_name ?? "",
		},
	});

	const handleSubmit: SubmitHandler<FormSchemaType> = (formData) => {
		updateTrackerName(formData.tracker_name);
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
				<fc.Input
					name="tracker_name"
					label="Tracker Name"
					placeholder="Enter tracker name"
					description="Give your tracker a meaningful name to help identify it."
				/>
				<ui.Button size="lg" type="submit">
					Save
				</ui.Button>
			</form>
		</ui.Form>
	);
};

export default TrackerNameForm;
