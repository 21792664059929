import { ui } from "@/components/ui";
import { InputTypeZ } from "@/definitions/inputs";
import { useDiaryTemplateByIdQuery } from "@/features/builder/api/selectDiaryTemplateById";
import { tableComponents as tc } from "@/features/patients/features/overview";
import { hooks as h } from "@/hooks";
import type { ExtendedDiaryTemplateInputT } from "@/types/database/diaries";
import { Outlet, createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { sortInputs } from "../../../../../../features/inputs/services/sortInputs";

const InputInputId = () => {
	const { inputId, diaryTemplateId } = Route.useParams();
	const { data: template } = useDiaryTemplateByIdQuery(diaryTemplateId);

	h.useNav([
		{
			label: "Templates",
			route: { to: "/templates/diary-templates" },
		},
		{
			label: "Form Item",
			route: {
				to: "/templates/diary-template/$diaryTemplateId",
				params: { diaryTemplateId: t.diary_template_id },
			},
			options: sortInputs(template?.diary_inputs)?.map(
				(t: ExtendedDiaryTemplateInputT) => ({
					label: t.inputs?.label,
					route: {
						to: "/templates/diary-template/$diaryTemplateId/input/$inputId",
						params: {
							diaryTemplateId: t.diary_template_id,
							inputId: t.input_id,
						},
					},
				}),
			),
		},
	]);

	return (
		<div>
			<div className="flex justify-between items-center gap-4 px-2 py-4 w-full">
				<ui.Input className="text-xl" value={template?.diary_name} />

				<tc.AddInputDialog diaryTemplateId={diaryTemplateId} />
			</div>

			<div className="flex lg:flex-row flex-col">
				<ui.ResizablePanelGroup direction="horizontal">
					<ui.ResizablePanel>
						<Outlet />
					</ui.ResizablePanel>
					<ui.ResizableHandle className="mx-2" />
					<ui.ResizablePanel>
						<hr className="border-slate-300 lg:hidden my-8 w-full" />
						<tc.OrderInputs
							inputId={inputId}
							diaryInputs={template?.diary_inputs}
						/>
					</ui.ResizablePanel>
				</ui.ResizablePanelGroup>
			</div>
		</div>
	);
};

export const LabelZ = z.string().nullable();
export const MaxValueZ = z.number().default(10).nullable();
export const MinValueZ = z.number().default(0).nullable();
export const MaxLengthZ = z.number().nullable();
export const OptionsZ = z.array(z.string()).nullable();

export const InputZ = z.object({
	input_key: InputTypeZ,
	label: LabelZ,
	max_value: MaxValueZ,
	min_value: MinValueZ,
	max_length: MaxLengthZ,
	options: OptionsZ,
});

export const InputSchemaZ = z.object({
	input: InputZ,
});

export type InputSchemaT = z.infer<typeof InputSchemaZ>;

export const Route = createFileRoute(
	"/_app/builder/diary-templates/$diaryTemplateId/input/$inputId/",
)({
	component: InputInputId,
	validateSearch: (search) => InputSchemaZ.parse(search),
});
