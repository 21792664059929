import { ui } from "../ui";

interface Props {
	children: React.ReactNode | string;
	asChild?: boolean;
}
const EditButton = ({ children, asChild = false }: Props) => {
	return (
		<ui.Button
			asChild={asChild}
			variant="link"
			className="my-0 px-2 py-0 w-fit h-fit"
		>
			{children}
		</ui.Button>
	);
};

export default EditButton;
