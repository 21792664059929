import { ui } from "@/components/ui";
import { cn } from "@/lib/utils";
import { services as s } from "@/services";
import dayjs from "dayjs";
import { ArrowUpDown, Calendar, Contact2 } from "lucide-react"; // Add ArrowUpDown import
import { FormInput } from "lucide-react";
import { useState } from "react"; // Add useState import
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "../../../components/ui/accordion";
import { Button } from "../../../components/ui/button";
import { Separator } from "../../../components/ui/separator";
import type { StatefulDiaryT } from "../../../types/database/diaries";
import OverallChart from "../../charts/components/OverallChart";
import { processGraphEntryValues } from "../../charts/utils/groupByInputId";
import { extractEntryValuesFromEntries } from "../../entries/hooks/useExtractEntryValues";
import EventCard from "../../events/components/EventCard";
import SchedulePreview from "../../schedules/components/SchedulePreview";
import EditTracker from "../../trackers/components/EditTracker";
import ActionCard from "./ActionCard";
import EntryCard from "./EntryCard";
interface Props {
	diary: StatefulDiaryT;
}

const HistoryByTracker = (props: Props) => {
	const [isEditingTracker, setIsEditingTracker] = useState(false);
	const [sortAscending, setSortAscending] = useState(false);

	return (
		<div className={cn("flex flex-col gap-y-4 w-full")}>
			{props.diary.trackers_view?.map((tracker, index) => {
				const events = props.diary.events_view.filter((event) =>
					(tracker.events as { event_id: string }[])
						?.map((e) => e.event_id)
						.includes(event.event_id),
				);

				const entryValues =
					processGraphEntryValues(
						extractEntryValuesFromEntries(tracker.entries_view),
					)?.at(0) ?? [];
				const graphInputType = entryValues?.at(0)?.input_key;

				const actions = props.diary.actions_view;

				return (
					<div
						key={tracker.tracker_id}
						className="space-y-2 bg-white dark:bg-slate-900 shadow-sm px-4 py-4 border rounded-lg w-full"
					>
						{/* Tracker Header */}
						<div className="mb-4">
							<div className="flex flex-row justify-between items-start w-full">
								<h3 className="mb-2 font-semibold text-xl">
									{tracker.tracker_name ?? `Intervention #${index + 1}`}
								</h3>
								<Button
									onClick={() => setIsEditingTracker(!isEditingTracker)}
									variant="link"
									className="my-0 px-2 py-0 w-fit h-fit"
								>
									{isEditingTracker ? "Done" : "Edit"}
								</Button>
							</div>
							{isEditingTracker ? (
								<EditTracker tracker={tracker} />
							) : (
								<p className="flex items-center gap-2 text-muted-foreground text-sm">
									{s.time.formatDate(tracker.start_date)} -{" "}
									{tracker.end_date === null
										? "Now"
										: s.time.formatDate(tracker.end_date)}
								</p>
							)}
						</div>
						<Separator />
						{/* Contact Info */}
						{events.map((event) => (
							<EventCard
								editable={isEditingTracker}
								showHeader={false}
								addable={false}
								className="bg-transparent shadow-none mx-0 p-0 px-0 border-none"
								key={event.event_id}
								event={event}
							/>
						))}
						<Separator />

						<Accordion collapsible type="single">
							{/* <EditTracker tracker={tracker} /> */}
							<div className="max-h-sm">
								<OverallChart
									entryValues={extractEntryValuesFromEntries(
										tracker.entries_view,
									)}
									inputType={graphInputType}
								/>
							</div>

							<AccordionItem value="entries">
								<AccordionTrigger className="text-sm">
									<span>See Diary Entries</span>
									<ui.Button
										className="mr-2 ml-auto py-0 h-fit"
										variant="ghost"
										size="sm"
										onClick={(e) => {
											e.stopPropagation();
											setSortAscending(!sortAscending);
										}}
									>
										<ArrowUpDown className="w-4 h-4" />
										{sortAscending ? "Newest First" : "Oldest First"}
									</ui.Button>
								</AccordionTrigger>
								<AccordionContent className="space-y-2">
									{[
										...tracker.entries_view.map((e) => ({
											...e,
											type: "entry",
											time: e.calculated_entry_time,
											id: e.entry_id,
										})),
										...actions.map((a) => ({
											...a,
											type: "action",
											time: a.action_time,
											id: a.action_id,
										})),
									]
										.sort((a, b) =>
											sortAscending
												? dayjs(a.time).diff(dayjs(b.time))
												: dayjs(b.time).diff(dayjs(a.time)),
										)
										.map((log) =>
											log.type === "entry" ? (
												<EntryCard
													editable={false}
													key={log.id}
													entry={log}
													size="sm"
												/>
											) : (
												<ActionCard key={log.id} action={log} />
											),
										)}
								</AccordionContent>
							</AccordionItem>

							<AccordionItem value="tracker-details">
								<AccordionTrigger className="text-sm">
									Show More Details
								</AccordionTrigger>
								<AccordionContent className="space-y-2 pt-2">
									<div className="space-y-1">
										<p className="flex items-center gap-2 font-medium text-slate-600 text-sm">
											<Contact2 size={20} />
											Contact Person
										</p>
										<p>{tracker.contact_name}</p>
									</div>
									<Separator />

									{/* Form Details */}
									{tracker.form && (
										<div className="space-y-2 mb-4">
											<div className="flex items-center gap-2 font-medium text-slate-600 text-sm">
												<FormInput size={20} />
												Forms
											</div>
											<div>
												<p className="text-md text-slate-800">
													{tracker.form.form_name}
												</p>
												<p className="text-slate-500 text-sm">
													{tracker.form.form_description}
												</p>
											</div>
										</div>
									)}

									{/* Schedules */}
									{/* Show backup schedule first since it's the initial schedule */}

									{dayjs(tracker.start_date).day !==
										dayjs(tracker.reference_date).day &&
										tracker.back_up_schedules && (
											<>
												<Separator />
												<div className="flex items-center gap-2 font-medium text-slate-600 text-sm">
													<Calendar size={20} />
													Initial monitoring schedule ($
													{s.time.formatDate(tracker.start_date)} until
													{s.time.formatDate(tracker.reference_date)})
												</div>
												<SchedulePreview
													title={`Initial monitoring schedule (${s.time.formatDate(
														tracker.start_date,
													)} until ${s.time.formatDate(
														tracker.reference_date,
													)})`}
													schedule={tracker.back_up_schedules}
												/>
											</>
										)}
									<Separator />
									<div className="flex items-center gap-2 font-medium text-slate-600 text-sm">
										<Calendar size={20} />
										Monitoring schedule
										<br />
										(Starting {s.time.formatDate(tracker.reference_date)})
									</div>
									<SchedulePreview
										title={`Monitoring schedule (starting ${s.time.formatDate(
											tracker.reference_date,
										)})`}
										schedule={tracker.schedules}
									/>
								</AccordionContent>
							</AccordionItem>
						</Accordion>
					</div>
				);
			})}
		</div>
	);
};

export default HistoryByTracker;
