import { supabaseClient } from "@/lib/supabase";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import type { UuidT } from "@/types/zod/auth";
import { injection_contents_key } from "./selectInjectionContents";

interface InjectionContents {
	injection_substance: string;
	dose: string;
	unit: string;
}

interface Props {
	user_id: string;
	contents: InjectionContents;
}

export async function handleNewInjectionContents(props: Props) {
	const { data, error } = await supabaseClient.rpc(
		"handle_new_injection_contents",
		{
			p_user_id: props.user_id,
			p_injection_substance: props.contents.injection_substance,
			p_dose: props.contents.dose,
			p_unit: props.contents.unit,
		},
	);

	if (error) throw error;
	return data as UuidT;
}

export const useHandleNewInjectionContents = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ["new-injection-contents"],
		mutationFn: handleNewInjectionContents,
		onError: (error) => {
			toast.error("Failed to create injection contents");
			console.error(error);
		},
		onSuccess: (contentsId) => {
			toast.success("New injection contents created");
			return contentsId;
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: injection_contents_key });
		},
	});
};
