import { useAuth } from "../utils/auth";

const useHome = () => {
	const auth = useAuth();
	if (auth.roles.isPatient) {
		return { to: "/diaries", label: "Diary" };
	}
	if (auth.roles.isClinician) {
		return { to: "/patients", label: "Patients" };
	}
	if (auth.isAuthenticated) {
		return { to: "/profile", label: "Profile" };
	}
	return null;
};

export default useHome;
