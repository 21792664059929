import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

interface AutoCompleteProps {
	value?: string;
	fetchSuggestions: (query: string) => Promise<(string | null)[]>;
	onChange?: (value: string) => void;
	options?: string[];
	placeholder?: string;
}

export default function Autocomplete({
	value = "",
	fetchSuggestions,
	onChange,
	options = [],
	placeholder,
}: AutoCompleteProps) {
	const [query, setQuery] = useState(value);
	const [debouncedQuery] = useDebounce(query, 300);
	const [suggestions, setSuggestions] = useState<string[]>([]);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [isLoading, setIsLoading] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	console.log(suggestions);

	const fetchSuggestionsCallback = useCallback(
		async (q: string) => {
			if (q.trim() === "") {
				setSuggestions([]);
				return;
			}
			setIsLoading(true);
			try {
				const results = await fetchSuggestions(q);
				setSuggestions(
					results.filter((result): result is string => result !== null),
				);
			} catch (error) {
				console.error("Error fetching suggestions:", error);
				setSuggestions([]);
			} finally {
				setIsLoading(false);
			}
		},
		[fetchSuggestions],
	);

	useEffect(() => {
		if (debouncedQuery && isFocused) {
			fetchSuggestionsCallback(debouncedQuery);
		} else {
			setSuggestions([]);
		}
	}, [debouncedQuery, fetchSuggestionsCallback, isFocused]);

	useEffect(() => {
		if (options.length > 0) {
			setSuggestions(options);
		}
	}, [options]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		setQuery(newValue);
		onChange?.(newValue);
		setSelectedIndex(-1);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "ArrowDown") {
			e.preventDefault();
			setSelectedIndex((prev) =>
				prev < suggestions.length - 1 ? prev + 1 : prev,
			);
		} else if (e.key === "ArrowUp") {
			e.preventDefault();
			setSelectedIndex((prev) => (prev > 0 ? prev - 1 : -1));
		} else if (e.key === "Enter" && selectedIndex >= 0) {
			setQuery(suggestions[selectedIndex]);
			setSuggestions([]);
			setSelectedIndex(-1);
		} else if (e.key === "Escape") {
			setSuggestions([]);
			setSelectedIndex(-1);
		}
	};

	const handleSuggestionClick = (suggestion: string) => {
		setQuery(suggestion);
		onChange?.(suggestion);
		setSuggestions([]);
		setSelectedIndex(-1);
	};

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		// Delay hiding suggestions to allow for click events on suggestions
		setTimeout(() => {
			setIsFocused(false);
			setSuggestions([]);
			setSelectedIndex(-1);
		}, 200);
	};

	return (
		<div className="mx-auto w-full">
			<div className="relative">
				<Input
					type="text"
					placeholder={placeholder ?? "Search..."}
					value={query}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					onFocus={handleFocus}
					onBlur={handleBlur}
					className="pr-10 w-full"
					aria-label="Search input"
					aria-autocomplete="list"
					aria-controls="suggestions-list"
					aria-expanded={suggestions.length > 0}
				/>
				<div
					className="top-0 right-0 absolute flex justify-center items-center pr-4 h-full"
					aria-label="Search"
				>
					<Search size={20} />
				</div>
			</div>
			{isLoading && isFocused && (
				<div
					className="z-10 absolute bg-background opacity-100 shadow-sm mt-2 p-2 border rounded-md origin-top transition-all duration-200 ease-in-out scale-100"
					aria-live="polite"
				>
					Loading...
				</div>
			)}
			{suggestions.length > 0 && !isLoading && isFocused && (
				<ul
					id="suggestions-list"
					className="z-10 absolute bg-white opacity-100 shadow-sm mt-2 border rounded-md w-full max-w-md text-sm origin-top transition-all duration-200 ease-in-out scale-100"
					role="listbox"
				>
					{suggestions.map((suggestion, index) => (
						<li
							key={suggestion}
							className={`px-4 py-2 cursor-pointer hover:bg-muted 
							transition-colors duration-150 ease-in-out
							${index === selectedIndex ? "bg-muted" : ""}`}
							onClick={() => handleSuggestionClick(suggestion)}
							onKeyDown={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									handleSuggestionClick(suggestion);
								}
							}}
							tabIndex={0}
							role="option"
							aria-selected={index === selectedIndex}
						>
							{suggestion}
						</li>
					))}
				</ul>
			)}
		</div>
	);
}
