import { supabaseClient } from "@/lib/supabase";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";

export async function selectOrthopaedicAreas() {
	const { data, error } = await supabaseClient
		.from("orthopaedic_areas")
		.select("orthopaedic_area");

	if (error) throw error;
	return data;
}

export const orthopaedicAreasQueryOptions = () => {
	return queryOptions({
		queryKey: ["orthopaedic_areas"],
		queryFn: () => selectOrthopaedicAreas(),
	});
};

export function useOrthopaedicAreasQuery() {
	return useSuspenseQuery(orthopaedicAreasQueryOptions());
}
