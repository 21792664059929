import { useFormContext } from "react-hook-form";
import { ui } from "../ui";

interface Props {
	name: string;
	label?: string;
	description?: string;
	placeholder?: string;
	disabled?: boolean;
	tabIndex?: number;
	type?: "number" | "text";
	id?: string;
	autocomplete?: string;
	className?: string;
}

const Input: React.FC<Props> = ({
	name,
	label,
	id,
	disabled,
	tabIndex,
	autocomplete,
	type,
	description,
	placeholder,
	className,
}) => {
	const { control } = useFormContext();
	return (
		<ui.FormField
			control={control}
			name={name}
			disabled={disabled}
			data-cy={name}
			render={({ field }) => (
				<ui.FormItem>
					{!!label && <ui.FormLabel>{label}</ui.FormLabel>}
					{description && (
						<ui.FormDescription>{description}</ui.FormDescription>
					)}
					<ui.FormControl>
						<ui.Input
							id={id}
							autoComplete={autocomplete}
							type={type ?? "text"}
							tabIndex={tabIndex}
							{...field}
							value={field.value ?? ""}
							placeholder={placeholder}
							className={className}
						/>
					</ui.FormControl>
					<ui.FormMessage />
				</ui.FormItem>
			)}
		/>
	);
};

export default Input;
