import { ActionIcon } from "@/assets/actions/ActionIcon";
import type { ActionDefinitionT } from "@/types/database/actions";
import Call from "../../../../../components/common/Call";
interface Props {
	action: ActionDefinitionT;
}

const TakeActionTitle = (props: Props) => {
	return (
		<Call
			size="sm"
			variant="info"
			icon={ActionIcon[props.action?.action_key]}
			words={props.action?.action_name}
		>
			<p className="text-slate-600 text-sm">{props.action.definition}</p>
		</Call>
	);
};

export default TakeActionTitle;
