import { ui } from "@/components/ui";
import {
	NavigationMenuLink,
	NavigationMenuList,
} from "@/components/ui/navigation-menu";
import { useLogoutMutation } from "@/features/auth/api/useLogoutMutation";
import { useProfileQuery } from "@/features/profile/api/selectProfile";
import { cn } from "@/lib/utils";
import type { ExtendedProfileT } from "@/types/database/profiles";
import { useAuth } from "@/utils/auth";
import { Link } from "@tanstack/react-router";
import { LogOut, MessageCircleQuestion } from "lucide-react";
import * as React from "react";

import { buttonVariants } from "../../../components/ui/button";
import { filterAppNavigation } from "../services/filterAppNavigation";
import { type PageT, navigationContent } from "./navigation-content";

function getButtonClassNames(
	page: PageT,
	checkIfSelected: (href: string) => boolean,
) {
	const selected = checkIfSelected(page.href);

	return cn(
		buttonVariants({ variant: page.variant ?? "ghost", size: "sm" }),
		"gap-x-[6px]",
		page.variant === "ghost" && "bg-transparent ring-0",
		!page.variant && "border-0 bg-transparent",
		page.variant !== "ghost" && "ml-2",
		selected ? "underline underline-offset-4" : "",
	);
}

function DesktopNavigation() {
	const auth = useAuth();
	const { data: profile } = useProfileQuery();
	const logout = useLogoutMutation();
	const numberNavigationItems = filterAppNavigation(
		navigationContent,
		auth.roles?.roles,
		profile as ExtendedProfileT,
	).length;

	const checkIfSelected = (routeName: string) => {
		return (
			window.location.href
				.split("/")[3]
				?.includes(routeName.split("/")[1] as string) ?? false
		);
	};

	return (
		<>
			<NavigationMenuList className="">
				{filterAppNavigation(
					navigationContent,
					auth.roles?.roles,
					profile as ExtendedProfileT,
				).map((page: PageT) => {
					return (
						<div key={page.href}>
							<ui.TooltipProvider>
								<ui.Tooltip>
									<ui.TooltipTrigger
										asChild
										className={getButtonClassNames(page, checkIfSelected)}
									>
										<Link
											data-testid={`${page.href}-button`}
											className=""
											key={page.href}
											to={page.href}
										>
											{page.icon}
											<span
												className={cn(
													page.iconOnly && "sr-only",
													numberNavigationItems > 4 && "sr-only",
												)}
											>
												{page.name}
											</span>
										</Link>
									</ui.TooltipTrigger>
									<ui.TooltipContent className="max-w-sm">
										<h3 className="text-slate-900 text-sm">{page.name}</h3>
										<p className="text-slate-600 text-sm">{page.description}</p>
									</ui.TooltipContent>
								</ui.Tooltip>
							</ui.TooltipProvider>
						</div>
					);
				})}
			</NavigationMenuList>
			<ui.Separator orientation="vertical" className="bg-slate-300 mx-2 h-10" />
			<div className="flex justify-center items-center gap-x-2">
				<ui.Button
					data-testid="help-button"
					asChild
					variant="outline"
					size="sm"
				>
					<Link
						to={auth.roles.isClinician ? "/help/clinician" : "/help/patient"}
						className="text-slate-900"
					>
						<MessageCircleQuestion />
						<span className={cn(numberNavigationItems > 4 ? "sr-only" : "")}>
							Help
						</span>
					</Link>
				</ui.Button>
				<ui.Button
					data-testid="logout-button"
					className=""
					onClick={() => logout.mutate()}
					variant="outline"
					size="sm"
				>
					<LogOut />
					<span className={cn(numberNavigationItems > 4 ? "sr-only" : "")}>
						Logout
					</span>
				</ui.Button>
			</div>
		</>
	);
}

export default DesktopNavigation;

const ListItem = React.forwardRef<
	React.ElementRef<"a">,
	React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
	return (
		<li>
			<NavigationMenuLink asChild>
				<a
					ref={ref}
					className={cn(
						"hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground block h-fit select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors",
						className,
					)}
					{...props}
				>
					<div className="line-clamp-2 font-medium text-md leading-none">
						{title}
					</div>
					<p className="line-clamp-2 text-muted-foreground text-sm leading-snug">
						{children}
					</p>
				</a>
			</NavigationMenuLink>
		</li>
	);
});
ListItem.displayName = "ListItem";
