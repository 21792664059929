import { Outlet, createFileRoute } from "@tanstack/react-router";

import UnauthorizedPage from "@/components/pages/UnauthorizedPage";
import useIsMyDiary from "@/features/patients/hooks/useIsMyDiary";

const EntryPage = () => {
	const { isMyDiary } = useIsMyDiary();

	return (
		<>
			{isMyDiary ? (
				<UnauthorizedPage message="As this is not your diary you cannot add entries." />
			) : (
				<Outlet />
			)}
		</>
	);
};

export const Route = createFileRoute(
	"/_app/diary/$diaryId/new/tracker/$trackerId/entry/$entry",
)({
	component: EntryPage,
});
