import { ui } from "@/components/ui";
import {
	createUniqueInjectionOptions,
	toFilteredUniqueInjectionOptions,
} from "@/features/injection/utils/injectionOptions";
import { services as s } from "@/services";
import { useFieldArray, useFormContext } from "react-hook-form";
import { formComponents as fc } from "../../../components/form";
import GroupedFields from "../../../components/form/GroupedFields";
import { sideOptions } from "../../events/utils/sideOptions";
import useInjectionDetailsOptions from "../hooks/useInjectionDetailsOptions";
import InjectionContentsForm from "./InjectionContentsForm";
import type { InjectionDetailSchemaT } from "./InjectionDetailsForm";
import InjectionLocationForm from "./InjectionLocationForm";

interface Props {
	injectionId: string;
}

const InjectionDetailsFormFields = ({ injectionId }: Props) => {
	const form = useFormContext();
	const { injectionLocations, injectionContents } =
		useInjectionDetailsOptions();

	const formValues = form.watch("injection_details");
	const { fields, append, remove } = useFieldArray<InjectionDetailSchemaT>({
		control: form.control, // control props comes from useForm (optional: if you are using FormProvider)
		name: "injection_details", // unique name for your Field Array
	});

	return (
		<div className="space-y-4 w-full text-lg">
			{fields.map((field, index) => (
				<div
					className="flex flex-col gap-y-4 bg-indigo-50 dark:bg-slate-900 shadow-md p-4 border border-slate-300 rounded-lg"
					key={field.id}
				>
					<h3 className="text-slate-900 dark:text-slate-100">
						Injection {index + 1}
					</h3>
					<div className="flex md:flex-row flex-col gap-x-2 gap-y-4">
						<fc.Select
							name={`injection_details.${index}.side`}
							label="Side"
							options={sideOptions}
						/>
						<fc.ComboBox
							name={`injection_details.${index}.orthopaedic_area`}
							label="Anatomic Area"
							options={createUniqueInjectionOptions(
								injectionLocations,
								"orthopaedic_area",
							)}
							addOption={<InjectionLocationForm />}
							clearable={true}
						/>
					</div>
					<GroupedFields>
						<fc.ComboBox
							name={`injection_details.${index}.injection_site`}
							label="Injection Site"
							options={toFilteredUniqueInjectionOptions(
								injectionLocations,
								"injection_site",
								"orthopaedic_area",
								formValues[index].orthopaedic_area,
							)}
							addOption={<InjectionLocationForm />}
							clearable={true}
						/>
						<fc.ComboBox
							name={`injection_details.${index}.injection_subsite`}
							label="Injection Subsite"
							options={toFilteredUniqueInjectionOptions(
								injectionLocations,
								"injection_subsite",
								"injection_site",
								formValues[index].injection_site,
							)}
							dependsOn={`injection_details.${index}.injection_subsite`}
							addOption={<InjectionLocationForm />}
							clearable={true}
						/>
					</GroupedFields>
					<fc.ComboBox
						name={`injection_details.${index}.injection_substance`}
						label="Injection Substance"
						options={createUniqueInjectionOptions(
							injectionContents,
							"injection_substance",
						)}
						addOption={<InjectionContentsForm />}
						clearable={true}
					/>
					<GroupedFields>
						<>
							<fc.ComboBox
								name={`injection_details.${index}.dose`}
								label="Dose"
								options={toFilteredUniqueInjectionOptions(
									injectionContents,
									"dose",
									"injection_substance",
									formValues[index].injection_substance,
									true,
								)}
								dependsOn={`injection_details.${index}.injection_substance`}
								addOption={<InjectionContentsForm />}
								clearable={true}
							/>
							<fc.ComboBox
								side="top"
								name={`injection_details.${index}.unit`}
								label="Unit"
								options={toFilteredUniqueInjectionOptions(
									injectionContents,
									"unit",
									"dose",
									formValues[index].dose,
								)}
								dependsOn={`injection_details.${index}.dose`}
								addOption={<InjectionContentsForm />}
								clearable={true}
							/>
						</>
					</GroupedFields>

					<div className="flex flex-row flex-wrap gap-4 mt-2">
						<ui.Button
							size="md"
							type="button"
							variant="destructive-secondary"
							onClick={() => {
								remove(index);
							}}
							disabled={fields.length === 1}
						>
							Remove
						</ui.Button>
						<ui.Button
							type="button"
							variant="secondary"
							size="md"
							onClick={() => {
								append({
									injection_id: injectionId,
									injection_detail_id: s.uuidv4(),
								});
							}}
						>
							Add Another Injection
						</ui.Button>
					</div>
				</div>
			))}
		</div>
	);
};

export default InjectionDetailsFormFields;
