import { Outlet, createFileRoute } from "@tanstack/react-router";

import { useDiaryByIdQuery } from "../../../../features/diary/api/getDiaryById";
import InterfaceButtons from "../../../../features/patients/features/interface/components/InterfaceButtons";
const PatientDiary = () => {
	const { diaryId } = Route.useParams();

	const { data: diary } = useDiaryByIdQuery(diaryId);
	return (
		<>
			<InterfaceButtons diary={diary} />

			<Outlet />
			<div className="h-16" />
		</>
	);
};

export const Route = createFileRoute("/_app/patients/$diaryId/_diary")({
	component: PatientDiary,
});
