import { commonComponents as cc } from "@/components/common";
import GoToDiariesCard from "@/features/diaries/components/GoToDiariesCard";
import GoToPatientsCard from "@/features/patients/components/GoToPatientsCard";
import { selectProfileByIdQueryOptions } from "@/features/profile/api/selectProfile";
import { profileComponents as pc } from "@/features/profile/components";
import MissingOrganisationAlert from "@/features/profile/components/MissingOrganisationAlert";
import Profile from "@/features/profile/components/Profile";
import useProfileProgress from "@/features/profile/hooks/useProfileProgress";
import { useAuth } from "@/utils/auth";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { Siren } from "lucide-react";
import { Suspense } from "react";

const ProfilePage = () => {
	const auth = useAuth();
	// useRedirectToOnboarding();

	const { data: profile } = useSuspenseQuery(
		selectProfileByIdQueryOptions(auth.id),
	);

	const { incomplete } = useProfileProgress(auth.id);

	return (
		<Suspense>
			<cc.Title words="Profile" />
			<pc.ProfileProgress userId={auth.id} />
			<MissingOrganisationAlert />
			{auth.roles?.isPatient && <GoToDiariesCard />}
			{(auth.roles?.isClinician ||
				auth.roles?.isSecretary ||
				auth.roles.isAdmin) && <GoToPatientsCard />}
			{incomplete === true && (
				<cc.Call
					className="mb-4"
					words="Profile Incomplete"
					icon={<Siren size={32} />}
				>
					To use Patient Watch, you should first complete your profile by adding
					any fields marked as missing below.
				</cc.Call>
			)}
			<Profile profile={profile} />
		</Suspense>
	);
};
export const Route = createFileRoute("/_app/profile/")({
	component: ProfilePage,
});

export default Profile;
