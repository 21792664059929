import { supabaseClient } from "@/lib/supabase";
import type { PatientsSearchT } from "@/routes/_app/patients";
import type { DiaryViewT } from "@/types/database/diaries";
import { type Auth, useAuth } from "@/utils/auth";
import { queryOptions, useQuery } from "@tanstack/react-query";

export const prepDiariesData = (settings: PatientsSearchT, auth?: Auth) => {
	if (!settings?.tab) throw new Error("Tab is required");

	if (!auth) throw new Error("Not logged in");

	return {
		...settings,
		diary_access: settings?.diary_access ?? undefined,
		user_id: settings?.diaries === "mine" ? auth.id : undefined,
	};
};

export async function selectPatientsDiaries(
	settings: PatientsSearchT,
	auth: Auth,
): Promise<DiaryViewT[]> {
	let query = supabaseClient.from("diaries_view").select("*");

	const { tab, user_id, diary_access, organisations } = prepDiariesData(
		settings,
		auth,
	);

	if (tab) {
		query = query.eq("tab", tab.toLowerCase());
	}

	if (user_id) {
		query = query.contains("direct_access", [user_id]);
	}

	if (diary_access && diary_access?.length > 0) {
		query = query.overlaps("direct_access", diary_access);
	}

	if (organisations && organisations?.length > 0) {
		query = query.in("organisation_id", organisations);
	}

	const { data: diaries, error } = await query;

	if (error) throw error;

	return diaries as DiaryViewT[];
}

export const createPatientsDiariesQueryKey = (search?: PatientsSearchT) => [
	"patients",
	"diaries",
	{ ...search },
];

export const patientsDiaryQueryOptions = (
	settings: PatientsSearchT,
	auth: Auth,
) => {
	return queryOptions({
		queryKey: createPatientsDiariesQueryKey(settings),
		queryFn: () => selectPatientsDiaries(settings, auth),
		staleTime: 0, // Force refetch
	});
};
export const usePatientsDiariesQuery = (settings: PatientsSearchT) => {
	const auth = useAuth();
	return useQuery(patientsDiaryQueryOptions(settings, auth));
};
