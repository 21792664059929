import useExtractTracker from "@/features/trackers/hooks/useExtractTracker";
import { useTrackerUpdate } from "@/features/trackers/hooks/useTrackerUpdate";
import Description from "../../../components/common/Description";
import { ui } from "@/components/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { formComponents as fc } from "../../../components/form";

interface Props {
	diaryId: string;
	trackerId: string;
}

const formSchema = z.object({
	hospital_number: z.string().min(1, "Hospital number is required"),
});

type FormSchemaType = z.infer<typeof formSchema>;

const TrackerHospitalNumberForm = ({ diaryId, trackerId }: Props) => {
	const tracker = useExtractTracker(diaryId, trackerId);
	const { updateHospitalNumber } = useTrackerUpdate(diaryId, trackerId);

	const form = useForm<FormSchemaType>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			hospital_number: tracker?.hospital_number ?? "",
		},
	});

	const handleSubmit: SubmitHandler<FormSchemaType> = (formData) => {
		updateHospitalNumber(formData.hospital_number);
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
				<fc.Input
					name="hospital_number"
					label="Hospital Number"
					placeholder="Enter hospital number"
					description="The hospital number helps identify the patient in the hospital system."
				/>
				<ui.Button size="lg" type="submit">
					Save
				</ui.Button>
			</form>
		</ui.Form>
	);
};

export default TrackerHospitalNumberForm;
