import type { StatefulDiaryT } from "@/types/database/diaries";
import { AlertCircle } from "lucide-react";
import { useMissingDetails } from "../hooks/useMissingDetails";

interface DiaryCardProps {
	diary: StatefulDiaryT;
}

const MissingDiaryDetails: React.FC<DiaryCardProps> = ({ diary }) => {
	const { missingDetails, hasMissingDetails } = useMissingDetails(diary);

	if (!hasMissingDetails) {
		return null;
	}

	return (
		<div className="flex items-start gap-2 bg-destructive/10 mb-4 p-2 rounded-md">
			<AlertCircle size={20} className="mt-1 text-destructive shrink-0" />
			<div className="space-y-2 text-sm">
				<p className="font-medium text-destructive">Missing Information</p>
				<ul className="space-y-0.5 text-muted-foreground">
					{missingDetails.trackers.missingDate?.map((tracker) => (
						<li key={tracker.tracker_id}>• Injection Date</li>
					))}
					{missingDetails.trackers.missingHospitalNumber?.map((tracker) => (
						<li key={tracker.tracker_id}>• Hospital Number</li>
					))}
					{missingDetails.injections.missingSide?.map((injection) => (
						<li key={injection.injection_id}>• Missing Side</li>
					))}
					{missingDetails.injections.missingOrthopaedicArea?.map(
						(injection) => (
							<li key={injection.injection_id}>• Missing Area</li>
						),
					)}
				</ul>
			</div>
		</div>
	);
};

export default MissingDiaryDetails;
