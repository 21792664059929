import ErrorPage from "@/components/pages/ErrorPage";
import { useDiariesViewQuery } from "@/features/diaries/api/selectDiariesView";
import { countPatientsDiariesQueryOptions } from "@/features/patients/api/useCountPatientsDiaries";
import { patientsDiaryQueryOptions } from "@/features/patients/api/useSelectPatientsDiaries";
import { selectPatientsSettingsByIdQueryOptions } from "@/features/patients/api/useSelectPatientsSettings";
import PatientsPage from "@/features/patients/components/PatientsPage";
import { selectProfileByIdQueryOptions } from "@/features/profile/api/selectProfile";
import { hooks as h } from "@/hooks";
import { useParams } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const PatientsPageWrapper = () => {
	const { diaryId } = useParams({ strict: false }) as { diaryId: string };
	h.useNav([{ label: "Patients", route: { to: "/patients" } }]);
	const { diariesCount, settings, profile, diaries } = Route.useLoaderData();

	return (
		<PatientsPage
			diaryId={diaryId}
			diariesCount={diariesCount}
			settings={settings}
			diaries={diaries ?? []}
			profile={profile}
		/>
	);
};

export const StyleZ = z.enum(["table", "stack"]).catch("table");
export const TabZ = z.enum(["active", "pending", "inactive"]).catch("active");

export type StyleT = z.infer<typeof StyleZ>;
export type TabT = z.infer<typeof TabZ>;

export const SortingZ = z.array(
	z.object({
		desc: z.boolean(),
		id: z.string(),
	}),
);

const defaultPageSize = 10;

export const PaginationZ = z.object({
	pageSize: z.number().default(defaultPageSize),
	pageIndex: z.number().default(0),
});

export type PaginationT = z.infer<typeof PaginationZ>;
export const patientsSchemaZ = z
	.object({
		columns: z.string().catch("").optional(),
		style: StyleZ.optional(),
		tab: TabZ.catch("active"),
		diaries: z.enum(["mine", "all"]).catch("mine").optional(),
		organisations: z.array(z.string()).catch(["all"]).optional(),
		diary_access: z.array(z.string().uuid()).optional(),
		pagination: PaginationZ.catch({ pageIndex: 0, pageSize: defaultPageSize }),
		sorting: SortingZ.optional(),
		refetcher: z.number().default(0),
	})
	.partial()
	.catch({
		tab: "active",
		pagination: { pageIndex: 0, pageSize: defaultPageSize },
		refetcher: 1,
	});

export type PatientsSearchT = z.infer<typeof patientsSchemaZ>;

export const Route = createFileRoute("/_app/patients")({
	component: PatientsPageWrapper,
	errorComponent: ({ error, reset }) => (
		<ErrorPage title="Patients Error" error={error as Error} reset={reset} />
	),
	validateSearch: (search) => {
		const defaultSearch = { tab: "active" as const };
		return patientsSchemaZ.parse({ ...defaultSearch, ...search });
	},
	loaderDeps: ({ search }) => ({ search }),
	loader: async ({ context, deps }) => {
		if (!context?.queryClient || !context.auth?.id) {
			throw new Error("Unauthorized access. Please log in.");
		}

		const patientsSettings = await context.queryClient.ensureQueryData(
			selectPatientsSettingsByIdQueryOptions(context.auth.id),
		);

		const settings = {
			...(patientsSettings?.patients_settings as PatientsSearchT),
			...deps.search,
		};

		const diariesCount = await context.queryClient.ensureQueryData(
			countPatientsDiariesQueryOptions(settings, context.auth),
		);

		const diaries = await context.queryClient.ensureQueryData(
			patientsDiaryQueryOptions(settings, context.auth),
		);

		const profile = await context.queryClient.ensureQueryData(
			selectProfileByIdQueryOptions(context.auth.id),
		);

		return {
			settings,
			diariesCount,
			diaries,
			profile,
		};
	},
});
