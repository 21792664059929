import React from "react";
import type { InjectionViewT } from "@/types/database/events";
import EditDialog from "@/components/common/EditDialog";
import EditInjectionDetailsSide from "./EditInjectionDetailsSide";
import EditInjectionDetailsOrthopaedicArea from "./EditInjectionDetailsOrthopaedicArea";

interface Props {
	diaryId: string;
	injection: InjectionViewT;
}

const EditInjectionAsPatient = ({ injection, diaryId }: Props) => {
	return (
		<div>
			{injection.injection_details?.map((id) => (
				<div key={id.injection_detail_id}>
					<EditDialog label="Side" info={id.side}>
						<EditInjectionDetailsSide
							diaryId={diaryId}
							injectionDetailId={id.injection_detail_id}
							side={id.side}
						/>
					</EditDialog>
					<EditDialog label="Anatomic Area" info={id.orthopaedic_area}>
						<EditInjectionDetailsOrthopaedicArea
							diaryId={diaryId}
							injectionDetailId={id.injection_detail_id}
							orthopaedicArea={id.orthopaedic_area}
						/>
					</EditDialog>
				</div>
			))}
		</div>
	);
};

export default EditInjectionAsPatient;
