import "chartjs-adapter-date-fns";

import { Chart as ChartJS, type LegendOptions, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { Chart } from "react-chartjs-2";

import type { StatefulDiaryT } from "../../../types/database/diaries";
import type { EntryValueViewT } from "../../../types/database/entries";
import { type LineSettingsT, generateData } from "../services/generateData";
import { defaultY, generateOptions } from "../services/generateOptions";

ChartJS.register(...registerables, annotationPlugin);
console.log("Annotation plugin:", ChartJS.registry.plugins.get("annotation")); // Add this line temporarily

interface Props {
	entryValues: EntryValueViewT[];
	trackers: StatefulDiaryT["trackers_view"];
	lineSettings?: LineSettingsT;
	legend?: LegendOptions<"line">;
	verticalLineAt?: Date;
}

const PainChart = ({
	entryValues,
	trackers,
	lineSettings,
	legend,
	verticalLineAt,
}: Props) => {
	// Add debug logging with proper date parsing
	console.log("Chart Configuration Debug:");
	console.log("verticalLineAt:", verticalLineAt);
	console.log(
		"Raw entry timestamps:",
		entryValues.length > 0
			? {
					first: entryValues[0].timestamp,
					last: entryValues[entryValues.length - 1].timestamp,
				}
			: "No entries",
	);

	const data = generateData({
		entryValues: entryValues,
		inputType: "pain-slider",
		trackers: trackers,
		lineSettings: lineSettings ?? {},
	});

	const annotationOptions = {
		drawTime: "afterDatasetsDraw",
		clip: false,
		annotations: {
			line1: {
				type: "line" as const,
				xMin: verticalLineAt,
				xMax: verticalLineAt,
				borderColor: "rgba(255, 99, 132, 1)",
				borderWidth: 4,
				display: true,
				yMin: 0,
				yMax: 10,
			},
		},
	};

	const options = generateOptions({
		datasets: data,
		y: { ...defaultY, max: 10 },
		legend: legend,
		plugins: {
			...legend,
			annotation: annotationOptions,
		},
	});

	// Debug the final configuration
	console.log("Annotation options:", annotationOptions);
	console.log("Full options:", options);

	return (
		<Chart
			type="line"
			options={options}
			className="mx-auto px-0 w-full max-w-lg min-h-[250px] max-h-[450px]"
			data={data}
		/>
	);
};

export default PainChart;
