import Hero from "@/features/website/components/Hero";
import Section from "@/features/website/components/Section";
import SectionTitle from "@/features/website/components/SectionTitle";
import Line from "@/features/website/story/Line";
import TimeBlock from "@/features/website/story/TimeBlock";
import { createFileRoute } from "@tanstack/react-router";

const Story = () => {
	return (
		<>
			<Section>
				<Hero
					caption="Our Story"
					title="Designed with Doctors, Loved by Patients"
					description="Expertly designed to reflect the needs of patients and their doctors - incorporating their ideas at every stage of development."
				/>
			</Section>

			<Section>
				<SectionTitle
					caption="The Beginning"
					className="mx-auto max-w-[600px]"
					title="How it started"
					description="Patient Watch started when Guy, our founder on a summer break from university, saw his surgeon father going through paper symptom diaries, posted to him by his patients and the idea of a digital diary was created."
				/>
			</Section>
			{/* <Section>
				<p className="mx-auto px-4 max-w-[600px] font-medium text-2xl text-center text-cyan-600">
					<i>"Surely this should all be automated?"</i>
				</p>
			</Section> */}
			<Section row={true}>
				<SectionTitle
					className="mx-auto"
					caption="Our Progress"
					title="The story so far"
					left={true}
					description="At the start of the project, the final goal was simply a digital version of the paper diary."
				/>
				<div>
					<TimeBlock
						title="First Prototype"
						caption="June 2021"
						description="The first prototyped was developed to allow patients to record their pain digitally."
					/>
					<Line />
					<TimeBlock
						title="Initial Testing"
						caption="October 2021"
						description="Initial testing at St John’s and Elizabeth’s hospital showed increased compliance compared to the paper diary."
					/>
					<Line />
					<TimeBlock
						title="Version 2.0"
						caption="May 2022"
						description="A doctor patients is built for continuous patient monitoring with analytics and automated actions. Version 2.0 reduced the required clinician time and improved appointment timing for patients."
					/>

					<Line />
					<TimeBlock
						title="Data Protection"
						caption="November 2022"
						description="The app is signed off as GDPR compliant passes its NHS Data Protection Impact Assessment."
					/>
					<Line />
					<TimeBlock
						title="NHS Quality Improvement Project"
						caption="January 2023"
						description="The Royal Surrey County hospital begin a Quality Improvement Project (ongoing) to measure the platform’s efficacy."
					/>
					<Line />
					<TimeBlock
						title="Foot Watch is Born"
						caption="June 2023"
						description="Following the success of Patient Watch, the Endocrinology department ask for an  expansion of the platform for diabetic foot ulcers."
					/>
				</div>
			</Section>
		</>
	);
};

export const Route = createFileRoute("/_website/story")({
	component: Story,
});

export default Story;
