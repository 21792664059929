import { findStateIcon } from "@/assets/states/StateIcon";
import { commonComponents as cc } from "@/components/common";
import { ui } from "@/components/ui";
import { findLatestEntry } from "@/features/diary/services/flattenEntriesView";
import { cn } from "@/lib/utils";
import type { StatefulDiaryT } from "@/types/database/diaries";
import dayjs from "dayjs";
import { useState } from "react";
import { Badge } from "../../../../../components/ui/badge";
import { Button } from "../../../../../components/ui/button";
import DiaryNameForm from "../../../../diary/edit/components/DiaryNameForm";
import DiaryOrganisationForm from "../../../../diary/edit/components/DiaryOrganisationForm";

interface Props {
	diary: StatefulDiaryT;
}

const StateCard = (props: Props) => {
	const diary = props.diary;
	const state = props.diary.state;
	const [editing, setEditing] = useState(false);
	return (
		<ui.Card
			className={cn(
				"w-full",
				["NEVER_WORKED", "HIGH", "LOW"].includes(props.diary.diary_status)
					? "border-red-200 bg-red-50"
					: "",
				["EMPTY_DIARY"].includes(props.diary.diary_status)
					? "border-amber-200 bg-amber-50"
					: "",
				["PAIN_REDUCED", "READY_TO_REVIEW"].includes(props.diary.diary_status)
					? "border-green-200 bg-green-50"
					: "",
			)}
		>
			<ui.CardHeader className="flex flex-row justify-between items-start w-full">
				<div className="flex flex-row gap-x-2 space-y-0 [&_svg]:w-[40px] [&_svg]:h-[40px]">
					{findStateIcon(state.state_key)}
					<h2 className="mt-0 text-xl font-medium w-fit text-slate-700 dark:text-slate-200">
						{state.name}
					</h2>
				</div>
				<Button
					className="p-0 mt-0 h-fit"
					variant="link"
					size="md"
					onClick={() => setEditing(!editing)}
				>
					{editing ? "Done" : "Edit"}
				</Button>
			</ui.CardHeader>
			{editing ? (
				<ui.CardContent className="flex flex-col gap-2">
					<cc.EditDialog label="Diary Name" info={diary.diary_name}>
						<DiaryNameForm diary={diary} diaryId={diary.diary_id} />
					</cc.EditDialog>

					<cc.EditDialog
						label="Diary Organisation"
						info={diary.organisation_name}
					>
						<DiaryOrganisationForm diary={diary} />
					</cc.EditDialog>
				</ui.CardContent>
			) : (
				<>
					<ui.CardContent className="flex flex-col gap-2">
						<div className="flex flex-row justify-between items-center mt-0 w-full">
							<h3 className="text-md text-slate-900 dark:text-slate-100">
								{props.diary.diary_name}
							</h3>
							<p className="text-sm font-medium text-slate-600 dark:text-slate-300">
								{props.diary.start_date
									? dayjs(props.diary.start_date).format("DD MMM YYYY")
									: "No Start Date"}{" "}
								-{" "}
								{props.diary.end_date
									? dayjs(props.diary.end_date).format("DD MMM YYYY")
									: "Now"}
							</p>
						</div>

						{findLatestEntry(props.diary) && (
							<p>
								Last entry{" "}
								{dayjs(
									findLatestEntry(props.diary)?.calculated_entry_time,
								).fromNow()}
							</p>
						)}
					</ui.CardContent>
					<ui.CardFooter>
						<Badge variant="secondary">{props.diary.organisation_name}</Badge>
					</ui.CardFooter>
				</>
			)}
		</ui.Card>
	);
};

export default StateCard;
