import { useAuth } from "@/utils/auth";

import Banner from "@/components/common/Banner";
import useHome from "@/hooks/useHome";
import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { useEffect, useState } from "react";

function LoggedInBanner() {
	const [showBanner, setShowBanner] = useState(false);
	const auth = useAuth();
	const home = useHome();
	useEffect(() => {
		if (auth.isAuthenticated) {
			setShowBanner(true);
		}
	}, [auth.isAuthenticated]);

	return (
		<Banner
			show={showBanner}
			setShow={setShowBanner}
			className={cn("bg-violet-300", auth.isAuthenticated ? "h-fit" : "h-0")}
		>
			<p
				className={cn(
					auth.isAuthenticated ? "flex flex-row gap-2 text-md" : "hidden",
				)}
			>
				You are logged in.
				<Link
					className="text-indigo-600 underline underline-offset-2"
					to={home?.to}
				>
					Go to {home?.label}
				</Link>
			</p>
		</Banner>
	);
}

export default LoggedInBanner;
