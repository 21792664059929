import { Link } from "@tanstack/react-router";
import { QrCode, Users } from "lucide-react";
import { Tabs, TabsList, TabsTrigger } from "../../../components/ui/tabs";

const tabs = [
	{
		label: "QR Code",
		icon: <QrCode size={20} />,
		path: "/new-diary/qr-code",
		value: "qr-code",
	},
	{
		label: "Manual",
		value: "manual",
		icon: <Users size={20} />,
		path: "/new-diary",
	},
];
const NewDiaryTabs = ({ value }: { value: string }) => {
	return (
		<div className="flex justify-center items-center gap-2 mx-auto mb-4 w-full">
			<Tabs defaultValue={value}>
				<TabsList>
					{tabs.map((tab) => (
						<TabsTrigger
							className="gap-2 px-8 py-2 text-lg"
							asChild
							key={tab.path}
							value={tab.value}
						>
							<Link key={tab.path} to={tab.path}>
								{tab.icon}
								{tab.label}
							</Link>
						</TabsTrigger>
					))}
				</TabsList>
			</Tabs>
		</div>
	);
};

export default NewDiaryTabs;
