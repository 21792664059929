import { supabaseClient } from "@/lib/supabase";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import type { PatientOptionT } from "../../../components/form/ComboBox";

export async function getCliniciansByOrganisation(organisationId?: string) {
	if (!organisationId) return [];

	const { data, error } = await supabaseClient.rpc(
		"get_profiles_by_organisation_and_role",
		{
			_organisation_id: organisationId,
			_role: "CLINICIAN",
		},
	);
	if (error) {
		console.error(error);
		toast.error(error.message);
		return null;
	}
	return [...data];
}

export const cliniciansByOrganisationQueryKey = (organisationId: string) => [
	"clinicians",
	{ organisation_id: organisationId },
];

export const cliniciansQueryOptions = (organisationId: string) => {
	return queryOptions({
		queryKey: cliniciansByOrganisationQueryKey(organisationId),
		queryFn: () => getCliniciansByOrganisation(organisationId),
	});
};

export const useCliniciansByOrganisationQuery = (organisationId: string) => {
	const options = cliniciansQueryOptions(organisationId);
	const query = useSuspenseQuery(options);
	return query;
};

export const useClinicianOptions = (organisationId: string) => {
	const { data } = useCliniciansByOrganisationQuery(organisationId);
	return data?.map((clinician) => {
		return { label: clinician?.name, value: clinician.user_id };
	}) as PatientOptionT[];
};
