import { formComponents as fc } from "@/components/form";
import type { OptionT } from "@/components/form/Select";
import useOrganisationOptions from "@/components/hooks/useOrganisationOptions";
import { ui } from "@/components/ui";
import { cn } from "@/lib/utils";
import { type PatientsSearchT, patientsSchemaZ } from "@/routes/_app/patients";
import { useAuth } from "@/utils/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { SlidersHorizontal } from "lucide-react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useSelectStaff } from "../../../../../profile/api/selectStaff";
import { usePatientsSettingsQuery } from "../../../../api/useSelectPatientsSettings";
import { useUpsertPatientsSettings } from "../../../../api/useUpsertPatientsSettings";

const TableMenu = () => {
	const auth = useAuth();
	const { data: patientsSettings } = usePatientsSettingsQuery();
	const { data: staff } = useSelectStaff();
	const { organisationIdOptions } = useOrganisationOptions();

	const { mutate: upsertPatientsSettings } = useUpsertPatientsSettings();

	const form = useForm<PatientsSearchT>({
		resolver: zodResolver(patientsSchemaZ),
		defaultValues: {
			organisations: patientsSettings?.patients_settings?.organisations ?? [],
			diaries: patientsSettings?.patients_settings?.diaries ?? "mine",
			diary_access: patientsSettings?.patients_settings?.diary_access ?? [],
		},
	});

	const onSubmit: SubmitHandler<PatientsSearchT> = async (formData) => {
		await upsertPatientsSettings({
			user_id: auth.id,
			updated_at: dayjs().toISOString(),
			patients_settings: formData,
		});
	};

	return (
		<ui.Popover>
			<ui.Button asChild variant="outline" size="sm">
				<ui.PopoverTrigger>
					<SlidersHorizontal size={24} />
					<span className="md:not-sr-only sr-only">Settings</span>
				</ui.PopoverTrigger>
			</ui.Button>

			<ui.PopoverContent align="end" className="flex flex-col gap-2">
				<h3 className="text-md text-slate-900 dark:text-slate-100">
					Table Menu
				</h3>
				<ui.Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className={cn("flex flex-col gap-y-4")}
					>
						<fc.Select
							label="Which diaries would you like to see:"
							name="diaries"
							options={[
								{ value: "all", label: "All Diaries" },
								{ value: "mine", label: "My Diaries" },
							]}
						/>
						<div className="space-y-1">
							<ui.Label>Filter by Diary Access</ui.Label>
							<fc.MultiSelect
								name="diary_access"
								options={
									staff?.map((s) => ({
										value: s.user_id,
										label: s.name,
									})) as OptionT[]
								}
								trigger="Select Users"
							/>
						</div>
						<div className="space-y-1">
							<ui.Label>Filter Diaries by Organisation:</ui.Label>
							<fc.MultiSelect
								name="organisations"
								options={organisationIdOptions}
								trigger="Select Organisations"
							/>
						</div>
						<ui.Button
							onClick={() => {
								console.error(form.formState.errors);
							}}
							type="submit"
							size="sm"
						>
							Save
						</ui.Button>
					</form>
				</ui.Form>
			</ui.PopoverContent>
		</ui.Popover>
	);
};

export default TableMenu;
