import { ui } from "@/components/ui";
import { services as s } from "@/services";
import type { ExpandedSchedule } from "@/types/database/schedule";
import dayjs from "dayjs";
import { useState } from "react";
import TimingExplainer from "./TimingExplainer";
interface Props {
	title: string;
	schedule: ExpandedSchedule;
}

const SchedulePreview = ({ schedule }: Props) => {
	const [expanded, setExpanded] = useState(false);

	const timings = expanded ? schedule.timings : schedule.timings.slice(0, 3);
	return (
		<div className="flex-1 min-w-0">
			<div className="flex gap-2 justify-between items-center">
				<h3 className="w-full text-md text-slate-800 text-wrap">
					{schedule.schedule_name}
				</h3>
			</div>
			<div className="pl-2 space-y-1">
				{timings.map((timing) => (
					<div
						key={timing.timing_id}
						className="flex gap-2 items-center text-sm text-slate-600"
					>
						<div className="w-1 h-1 rounded-full bg-slate-400" />
						{timing.is_autoname ? (
							<TimingExplainer timing={timing} />
						) : (
							<span>{timing.label}</span>
						)}
					</div>
				))}
				{schedule.timings.length > 3 && (
					<div className="flex gap-2 items-center text-sm text-indigo-600">
						<span onClick={() => setExpanded(!expanded)} className="italic">
							{expanded ? "Hide" : "Show"} {schedule.timings.length - 3} more
							timings
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default SchedulePreview;
